import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';


export default function ConversationalData(props) {
    // console.log("AccuracyTiles", props)
    return (
        <Box>
        <Stack direction="row" spacing={2} style={{ maxHeight: '600px' }}>
          <Box sx={
            props.selectedConv?{width: '60%',
            bgcolor: 'background.paper', position:'relative', overflowY: 'auto', maxHeight: '600px'}: {width: '100%',
            bgcolor: 'background.paper'}}>
          <List                     
          dense >

            {props.convData.map((item, index) => {
              return (
                <ListItem
                  secondaryAction={
                    <Typography>
                      <Chip label={item.isPass ? "Pass" : "Fail"} color={item.isPass ? "success" : "error"} />
                    </Typography>
                  }
                  disablePadding
                >
                  <ListItemButton
                    selected={index == props.selConvIn}
                    onClick={() => { props.selectConv(this, item, index) }}
                  >
                    <ListItemText
                      primary={item.Case}
                      secondary={`User message Count ${item.data.length}`} 
                      />
                  </ListItemButton>


                </ListItem>)

            })}
          </List>
          </Box>
          {props.selectedConv &&
            <List sx={{width: '40%', bgcolor: 'background.paper', boxShadow: "1", borderRadius: '5px'}}>
              <ListItem
              secondaryAction={
                <IconButton 
                  onClick={() => {props.setSelectedConv(null); props.setSelConvIn(null)}}
                  edge="end">
                  <CloseIcon sx={{ fontSize: 15 }}/>
                </IconButton>
              }
              disablePadding>
                <ListItemText
                  style={{
                    paddingLeft: 24,
                    paddingRight: 24,
                  }}
                  primary={`Session - ${props.selectedConv.data[0].data.sessionId}`}
                  secondary={null} />
                  
              </ListItem>
              <Divider />
              <Box style={{ position: 'relative', overflowY: "auto", maxHeight: '550px' }}>
                {
                  
                  props.selectedConv.data.map((element, cindex) => {
                    return (
                      <>
                        <ListItem>
                          <ListItemText
                            style={{ alignItems: 'flex-end', display: 'flex', flexDirection: 'row-reverse' }}
                            primary={<div style={{
                              maxWidth: '450px',
                              padding: '10px',
                              fontSize: '15px',
                              borderRadius: '10px',
                              background: "#f8f8f8",
                              borderBottomRightRadius: '0px'
                            }}>
                              <div>
                                {element.UserInput}
                              </div>
                            </div>}
                            secondary={null} />
                        </ListItem>
                        {element.Response.map((res, resIndex) => {
                          return (
                            <ListItem>
                              <ListItemText
                                primary={
                                  <div style={{
                                    maxWidth: '450px',
                                    padding: '10px',
                                    borderRadius: '10px',
                                    fontSize: '14px',
                                    background: "#579ffb",
                                    color: 'white',
                                    borderBottomLeftRadius: '0px'
                                  }}>
                                    <div>
                                      <strong>Bot</strong><br />
                                      {res.botResponse}
                                    </div>
                                    <Typography>
                                      <Chip size="small" label={res.isMatched?"Pass":"Fail"} color={res.isMatched?"success":"error"}
                                        onDelete={() => { props.selectExpected(this, cindex, resIndex) }}
                                        deleteIcon={<KeyboardArrowDownIcon sx={{
                                            mr: -1,
                                            transform: props.expected[`exp${cindex}${resIndex}`] ? 'rotate(-180deg)' : 'rotate(0)',
                                            transition: '0.2s',
                                          }}/>} />
                                    </Typography>
                                    <Box sx={{ 
                                        display: props.expected[`exp${cindex}${resIndex}`] ? '' : 'none', 
                                    transition: '1s' }}>
                                      <strong>Expected</strong><br />
                                      {res.Response}
                                      {res.Buttons && '\nButtons are: ' + res.Buttons}
                                    </Box>
                                  </div>
                                }
                              />
                            </ListItem>)
                        })}
                      </>
                    )
                  })
                }
              </Box>
            </List>
          }
        </Stack>
      </Box>
    )

}

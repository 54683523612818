import React, { useState } from 'react';
import XLSX from 'xlsx';
import saveAs from 'save-as'
import BotInputs from '../components/BotInputs'
import Box from '@mui/material/Box';
import NavBar from '../components/NavBar'
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';

import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import ConversationalData from '../components/ConversationalData';

import { useTypedSelector } from "../redux/root.reducer";

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'
// Load the exporting module.
import Exporting from 'highcharts/modules/exporting';
Exporting(Highcharts);
const APP_API_URL = "https://api.botomatic.ai";

var ConvTestStats = {

  chart: {
    type: 'pie'
  },
  title: {
    text: 'Conversation Flow Test'
  },
  tooltip: {
    pointFormat: '<b>{point.percentage:.1f}%</b>'
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>: {point.percentage:.1f} %'
      }
    }
  },

  series: [{
    name: "Conversation Flow",
    data: []
  }],

}
const size = 10

function ConvTest(props) {

  const { user } = useTypedSelector((state) => state.authState);

  const [progress, setProgress] = useState(0);
  const [total, setTotal] = useState(0);
  const [graphData, setGraphData] = useState(ConvTestStats);
  const [convData, setConvData] = useState([]);
  const [finalData, setfinalData] = useState([]);
  const [formData, setFormData] = useState({ env: user.env, userId: user.email, agentName: "", data: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [projects, setProjects] = useState([]);
  const [alertMessage, setAlertMessage] = useState("Something went wrong. Please try again later.");
  const [value, setValue] = React.useState("1");
  const [expected, setExpected] = useState({});
  const [selectedConv, setSelectedConv] = useState(null);
  const [selConvIn, setSelConvIn] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSelectedConv(null);
    setSelConvIn(null);
  };

  const selectConv = (event, conv, index) => {
    setSelectedConv(conv);
    setSelConvIn(index)
  }

  const selectExpected = (event, cindex, index) => {
    // if(selectedConv.data[cindex]?.Response[index])
    //   setSelectedConv(selectedConv.data[cindex].Response[index].expected = true)
    var expVal = true
    if (expected.hasOwnProperty([`exp${cindex}${index}`])) {
      expVal = !expected[`exp${cindex}${index}`]
    }
    const exp = { ...expected, ...{ [`exp${cindex}${index}`]: expVal } }
    setExpected(exp)
  }

  const handlerEnvSelection = event => {
    setFormData({
      ...formData,
      env: event.target.value
    })
    getAllAgent(event.target.value)

  }

  React.useEffect(() => {
    getAllAgent();
  }, [])

  const getAllAgent = (env) => {
    if (user.env) {
      env = user.env
    }
    const requestOptions = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.token
      },
      body: JSON.stringify({
        "env": env
      })
    };
    fetch(props.APP_API_URL + "/agentlist", requestOptions)
      .then(response => response.json())
      .then(data => {
        // console.log("ProjectsUrl result", data);
        const projectData = data._embedded.projects.map(proj => { return { name: proj.name, id: proj._links.self.href.split('projects/')[1] } });
        setProjects([...projectData]);
        // console.log("Formated data", projectData)
      })
      .catch(error => {
        // console.log(error);
        setIsLoading(false);
        if (env) {
          setAlertMessage("Something went wrong. Please try again later.")
          setShow(true);
        }
      });

  }

  const handleAgentSelection = event => {
    setShow(false);
    const requestOptions = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.token
      },
      body: JSON.stringify({
        "env": formData.env,
        "id": event.target.value
      })
    };
    let agentName = projects.find(item => item.id === event.target.value)
    fetch(props.APP_API_URL + "/agentendpoints", requestOptions)
      .then(response => response.json())
      .then(data => {
        // console.log("EndpointsUrl result", data);
        const endpointData = data._embedded.endpoints.filter(item => item.channel === 'rest' && item.name.toLowerCase().indexOf("conv") >= 0);
        if (endpointData.length > 0) {
          // setProjects(projectData);
          // console.log("endpointData data", endpointData)
          // console.log(event.target.value);
          // console.log("Agent Name", agentName);
          setFormData({
            ...formData,
            projectId: event.target.value,
            URLToken: endpointData[0].URLToken,
            agentName: agentName.name
          });
        } else {
          setAlertMessage("No Rest endpoint associate with this Project")
          setShow(true);
        }
      })
      .catch(error => {
        // console.log(error);
        setIsLoading(false);
        setAlertMessage("Something went wrong. Please try again later.")
        setShow(true);
      });
  }
  const handleUserIdChange = event => {
    // console.log(event.target.value);
    setFormData({ ...formData, userId: event.target.value });
  }


  const downloadClick = event => {
    // XLSX = require('xlsx');
    try {
      var wb = XLSX.utils.book_new();

      var FinalConvData = [];

      convData.map(item => {

        let data = {
        }

        if (item.hasOwnProperty("Case")) {
          data.Case = item.Case;
        }
        item.data.map(ele => {
          data.UserInput = ele.UserInput;
          data.Intent = ele.Intent
          ele.Response.map(res => {
            data = { ...data, ...res };
            FinalConvData.push(data);
            data = {}
          })
        })
      });

      // console.log("FinalConvData", FinalConvData)

      var botConvData = XLSX.utils.json_to_sheet(FinalConvData);
      // var accurayScoreData = XLSX.utils.json_to_sheet(accuracyScores);
      // var confusionMatrixData = XLSX.utils.json_to_sheet(confusionMatrix);
      // var detailedData = XLSX.utils.json_to_sheet(accuracyData.rawData);
      // var missedClassifiedData = XLSX.utils.json_to_sheet(missedClassified, { skipHeader: true });

      XLSX.utils.book_append_sheet(wb, botConvData, "Sheet1");
      // XLSX.utils.book_append_sheet(wb, accurayScoreData, "Intent Score");
      // XLSX.utils.book_append_sheet(wb, confusionMatrixData, "Confusion Matrix");
      // XLSX.utils.book_append_sheet(wb, detailedData, "Details");
      // XLSX.utils.book_append_sheet(wb, missedClassifiedData, "Missed Classified");

      var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${formData.userId}-${new Date().getTime()}.xlsx`);
    } catch (error) {
      setAlertMessage("Something went wrong. Please try again later.")
      setShow(true)
    }
  };

  const onConvSubmit = event => {
    setIsLoading(true);
    setShow(false);
    setProgress(0);
    setfinalData([]);
    setConvData([]);
    setGraphData(ConvTestStats);
    setSelectedConv(null);
    setSelConvIn(null);
    setExpected({});
    // console.log("formData", formData)
    if (formData.projectId != "--Select Bot--" &&
      formData.userId.trim() != '' &&
      formData.data.length > 0) {
      // Verify the data size and splice it to reduce payload size
      var testData = formData.data;
      setTotal(formData.data.length);
      if (formData.data.length > size)
        testData = formData.data.splice(0, size);
      else
        testData = formData.data.splice(0, formData.data.length);

      const requestOptions = {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + user.token
        },
        body: JSON.stringify({ ...formData, ...{ data: testData } })
        // body: {...formData, ...{data: data}}
      };
      // console.log("requestOptions", requestOptions)
      // console.log("formData", formData)     
      executeConvTestCases(requestOptions);
    } else {
      setAlertMessage("Please select bot, user id and utterance file")
      setShow(true);
      setIsLoading(false);
    }
  }

  React.useEffect(() => {

    if (finalData.length > 0) {
      // console.log("finalData", finalData);
      let passCount = finalData.filter(item => item.isPass);
      let failCount = finalData.filter(item => !item.isPass);

      let pass = { "name": "Pass", "y": passCount.length }
      let fail = { "name": "Fail", "y": failCount.length }

      ConvTestStats.series[0].data.push(pass);
      ConvTestStats.series[0].data.push(fail);
      setGraphData(ConvTestStats);
      setConvData((prevConvData) => ([...finalData]));
      setProgress((prevProgress) => (((total - formData.data.length) / total)) * 100);
    }
  }, [finalData])

  function executeConvTestCases(requestOptions) {
    fetch(props.APP_API_URL + '/conversationtest', requestOptions)
      .then(response => response.json())
      .then(data => {
        // console.log("convData", convData);
        setfinalData((preFinalData => ([...preFinalData, ...data])))
        if (formData.data == 0) {
          setIsLoading(false);
        } else {
          // console.log("Api result", data);
          // console.log("finalData", finalData);
          // setConvData(finalData);
          var testData = formData.data;
          if (formData.data.length > size)
            testData = formData.data.splice(0, size);
          else
            testData = formData.data.splice(0, formData.data.length);
          // console.log("convData", convData);

          const requestOptions = {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
              "Authorization": "Bearer " + user.token
            },
            body: JSON.stringify({ ...formData, ...{ data: testData } })
            // body: {...formData, ...{data: data}}
          };
          // console.log("requestOptions", requestOptions)
          executeConvTestCases(requestOptions)
        }
      })
      .catch(error => {
        // console.log(error);
        setIsLoading(false);
        setAlertMessage("Something went wrong. Please try again later.")
        setShow(true);
      });
  }

  // const filterData = event => {
  //   let fData = convData;
  //   if (event.target.value !== "all")
  //     let fData = convData.filter(item => item.isPass === event.target.value);
  //   setFilterData(fData);
  // }

  const onFileChange = event => {
    setIsLoading(true);
    setShow(false)

    try {
      const reader = new FileReader();
      if (reader.readAsBinaryString) {
        reader.onload = (event) => {
          const bstr = event.target.result;
          const workbook = XLSX.read(bstr, { type: 'binary' });
          const firstSheet = workbook.SheetNames[0];
          const excelRows = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
          if (Array.isArray(excelRows) && excelRows.length > 0) {
            let row = excelRows[0]
            if (row.hasOwnProperty("Case") && row.hasOwnProperty("UserInput")) {
              var totalConversations = []
              var CaseData = {}
              excelRows.map((item, index) => {
                let convData = { ...item };
                // // console.log(item);
                if (item.hasOwnProperty("Case")) {
                  if (index > 0) if (index > 0) {
                    totalConversations.push(CaseData);
                    CaseData = {}
                  }
                  CaseData.Case = item.Case
                  CaseData.data = [];

                  let ResponseData = {
                    UserInput: item.UserInput, Response: [],
                    Intent: item.Intent,
                    data: item.data ? JSON.parse(item.data) : item.data,
                    action: item.action
                  };
                  ResponseData.Response.push({
                    ResponseType: item.ResponseType,
                    Response: item.Response,
                    Buttons: item.Buttons
                  })
                  CaseData.data.push(ResponseData);

                } else if (item.hasOwnProperty("UserInput") && ("" + item.UserInput).trim() !== "") {
                  let ResponseData = {
                    UserInput: "" + item.UserInput, Response: [],
                    Intent: item.Intent,
                    data: item.data ? JSON.parse(item.data) : item.data,
                    action: item.action
                  };
                  ResponseData.Response.push({
                    ResponseType: item.ResponseType,
                    Response: item.Response,
                    Buttons: item.Buttons
                  });
                  CaseData.data.push(ResponseData);
                } else {
                  // if (item.ResponseType.toUpperCase() === "QUICKREPLY")
                  //   CaseData.data[CaseData.data.length - 1].Response[0].buttons = item.Response;
                  // else
                  CaseData.data[CaseData.data.length - 1].Response.push(item);
                }
                if (index === excelRows.length - 1)
                  totalConversations.push(CaseData);
              })
              setFormData({ ...formData, data: totalConversations });
            } else {
              setAlertMessage("Excel sheet dont't have 'Case' and 'UserInput' column. Please make sure it matches cases")
              setShow(true);
            }
          } else {
            setAlertMessage("No data found in excel")
            setShow(true);
          }
          setIsLoading(false);
          // console.log(excelRows);
        };
        if (event.target.files[0])
          reader.readAsBinaryString(event.target.files[0]);
        else
          setIsLoading(false);

      }
    } catch (error) {
      // console.log(error)
      setAlertMessage("Something went wrong. Please try again later.")
      setIsLoading(false);
      setShow(true)
    }
    // Update the state
    // this.setState({ selectedFile: event.target.files[0] });
    //     // console.log("selectedFile", event.target.files[0])
    //     var workbook = XLSX.readFile(event.target.files[0].name);
    // var sheet_name_list = workbook.SheetNames;
    // // console.log(XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list[0]]))

  };


  return (

    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" value={progress} />
        <Typography
          variant="caption"
          component="div"
        >{`${progress}%`}</Typography>
      </Backdrop>
      <NavBar page={"conversation"} />

      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 2 }}>
        <Typography variant="h6" component="h6"
          style={{ fontWeight: 'bold', padding: '10px', color: '#202020' }}>
          Conversation Testing
        </Typography>
        <Collapse in={show}>
          <Alert action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShow(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"><b>Error: </b>{alertMessage}</Alert>
        </Collapse>

        <BotInputs
          user={user}
          projects={projects}
          handleAgentSelection={handleAgentSelection}
          handlerEnvSelection={handlerEnvSelection}
          formData={formData}
          onFileChange={onFileChange}
          onSubmit={onConvSubmit}
          handleUserIdChange={handleUserIdChange}
          downloadClick={downloadClick}
          downData={convData} />

        {convData && convData.length > 0 && <Grid container spacing={"5"}>
          {/* <Grid item xs={12} md={12}>
            <HighchartsReact
              highcharts={Highcharts}
              options={graphData}
            />
          </Grid> */}
          <Grid item xs={12} md={12}>
            <TabContext value={value} >
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label={`All ${total}`} value="1" />
                  <Tab label={`Pass ${convData.filter(item => item.isPass).length}`} value="2" />
                  <Tab label={`Fail ${convData.filter(item => !item.isPass).length}`} value="3" />
                </TabList>
              </Box>
              <TabPanel value="1" sx={{ padding: '5px' }}>
                <ConversationalData
                  convData={convData} selConvIn={selConvIn} selectedConv={selectedConv}
                  setSelectedConv={setSelectedConv} setSelConvIn={setSelConvIn}
                  selectExpected={selectExpected} expected={expected}
                  selectConv={selectConv}

                />

              </TabPanel>
              <TabPanel value="2" sx={{ padding: '5px' }}>
                <ConversationalData
                  convData={convData.filter(item => item.isPass)} selConvIn={selConvIn} selectedConv={selectedConv}
                  setSelectedConv={setSelectedConv} setSelConvIn={setSelConvIn}
                  selectExpected={selectExpected} expected={expected}
                  selectConv={selectConv}
                />
              </TabPanel>
              <TabPanel value="3" sx={{ padding: '5px' }}>
                <ConversationalData
                  convData={convData.filter(item => !item.isPass)} selConvIn={selConvIn} selectedConv={selectedConv}
                  setSelectedConv={setSelectedConv} setSelConvIn={setSelConvIn}
                  selectExpected={selectExpected} expected={expected}
                  selectConv={selectConv}
                />
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>}




      </Box>
    </Box>

  );
}

export default ConvTest;


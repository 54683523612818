import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import XLSX from 'xlsx';
import saveAs from 'save-as'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'
import Card from 'react-bootstrap/Card'
import BotInputs from '../components/BotInputs'
import Box from '@mui/material/Box';
import NavBar from '../components/NavBar'
import SimpleText from '../components/units/SimpleText'
import AccuracyTables from '../components/AccuracyTables'
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import { useTypedSelector } from "../redux/root.reducer";


const scoreHeaders = [{ name: "Intent", value: "intent" },
{ name: "Identified", value: "identified" },
{ name: "Correctly Identified", value: "correctlyIdentified" },
{ name: "Expected", value: "expected" },
{ name: "Recall", value: "recall" },
{ name: "Precision", value: "precision" },
{ name: "F1 Score", value: "f1Score" }]

const botResponses = [{
    "intent": "DISCOUNT_DEN",
    "verify": "",
    "handling": "",
    "slots": "",
    "sentiments": "",
    "nodeId": "",
    "channel": "",
    "responses": [
        { "type": "text", "message": "Have you heard about our Discount Den?" },
        { "type": "text", "message": "With a yearly Discount Den® subscription you will have exclusive access to Frontier’s lowest available fares across our routes all year long." },
        { "type": "text", "message": "How cool is that!\nHere’s all the info you might need about our Discount Den: https://faq.flyfrontier.com/help/what-is-discount-den" }],
    "CreatedBy": "Deepak Giri",
    // "UpdatedBy": "Deepak Giri",
    // "LastModifiedDt": "1637595348000"
},
{"_id":{"$oid":"61b1f7f5c8f8830b2199aefa"},"intent":"TEST_INTENT","sentiments":"","nodeId":"","channel":"","responses":[{"type":"text","delay":{"$numberLong":"2000"},"message":"Have you heard about our Discount Den?"},{"type":"text","delay":{"$numberLong":"2000"},"message":"With a yearly Discount Den® subscription you will have exclusive access to Frontier’s lowest available fares across our routes all year long."},{"type":"text","delay":{"$numberLong":"2000"},"message":"How cool is that!\nHere’s all the info you might need about our Discount Den: https://faq.flyfrontier.com/help/what-is-discount-den"}],"CreatedBy":"Deepak Giri","UpdatedBy":"Deepak Giri","LastModifiedDt":"1637595348000","handling":"faq","intentConfirmation":"Did you say you wanted to discuss monkeys?","intentReflective":"I can get someone to help with monkeys.","slots":"","verify":false}
]

const APP_API_URL = "https://api.botomatic.ai";

function BotResponses(props) {
    const [open, setOpen] = React.useState(false);
    const [responsesData, setResponsesData] = React.useState([]);
    const { user } = useTypedSelector((state) => state.authState);

    React.useEffect(() => {
        const requestOptions = {
            method: 'post',
            headers: { 'Content-Type': 'application/json',
            "Authorization": "Bearer " + user.token }
        };
        fetch(props.APP_API_URL+'/responses', requestOptions)
            .then(response => response.json())
            .then(data => {
                // console.log("responses result", data);
                setResponsesData([...data]);
            })
            .catch(error => {
                // console.log(error);
                // setIsLoading(false);
                // setAlertMessage("Something went wrong. Please try again later.")
                // setShow(true);
            });
    }, [])

    return (

        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <NavBar page={"botresponses"}/>

            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 2 }}>
                <Typography variant="h6" component="h6"
                    style={{ fontWeight: 'bold', padding: '10px', color: '#202020' }}>
                    Bot Responses
                </Typography>
                <Divider />


                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>Intent</TableCell>
                                <TableCell align="right">Verify</TableCell>
                                <TableCell align="right">Handling</TableCell>
                                <TableCell align="right">Slots</TableCell>
                                <TableCell align="right">Channel</TableCell>
                                <TableCell align="right">NodeId</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {botResponses.map((row) => (
                                <>
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() => setOpen(!open)}
                                            >
                                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.intent}
                                        </TableCell>
                                        <TableCell align="right">{""+row.verify}</TableCell>
                                        <TableCell align="right">{row.handling}</TableCell>
                                        <TableCell align="right">{row.slots}</TableCell>
                                        <TableCell align="right">{row.channel}</TableCell>
                                        <TableCell align="right">{row.nodeId}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                                            <Collapse in={open} timeout="auto" unmountOnExit>
                                                <Box sx={{ margin: 1 }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <p><b>Intent Confirmation</b></p>
                                                            <p>{row.intentConfirmation}</p>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <p><b>Intent Reflective</b></p>
                                                            <p>{row.intentReflective}</p>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <p><b>Responses</b></p>
                                                        </Grid>
                                                        {row.responses.map(item => {
                                                            return <Grid item xs={4}>
                                                                <SimpleText data={item} />
                                                            </Grid>
                                                        })}
                                                    </Grid>

                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Box>
        </Box>

    );
}

export default BotResponses;

import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tile from '../components/units/Tile'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

export default function AccuracyTiles(props) {
    // console.log("AccuracyTiles", props)
    return (
        <Box sx={{ marginTop: '20px' }}>
            <Grid container spacing={2}>
                {
                    props.data && props.data.map((item, index) => {
                        return <Grid key={`atl${index}`} item xs={4}>
                            <Tile data={item} />
                        </Grid>
                    })
                }
                <Grid item xs={4}>
                    <Paper style={{ padding: '10px' }}>
                        <Typography variant="subtitle1" style={{ textTransform: 'capitalize' }}>
                            Accuracy Report
                        </Typography>
                        <Button variant="contained"
                            style={{ margin: "5px", textTransform: 'capitalize' }}
                            onClick={props.onSubmit}
                            size="small">
                            Download Excel
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        </Box >
    );
}

import React, { useState } from 'react';
import ReactDOM from "react-dom";
import Box from '@mui/material/Box';
import NavBar from '../components/NavBar'
import CssBaseline from '@mui/material/CssBaseline';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import parse from 'html-react-parser';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import BroadcastMessage from '../components/BroadcastMessage'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TabItem } from '@aws-amplify/ui-react';
import { useTypedSelector } from "../redux/root.reducer";

var infoData = {
    "Broadcast Message": {
        "isBroadcastMessage": "Show Message",
        "broadcastMessage": "Message Verbiage",
        info: "",
        "broadcastMessageInfo": "\nNOTE: To provide links in a Broadcast Message \nutilize HTML markup for the web channel. \nOnce saved here, the output will \ndisplay as it will be presented in the bot. \nTo remain consistent, use the \nfollowing as a template for links: \n\n<a target='_blank' \nhref='https://URL_for_link' \nstyle='color: #f7f7f7; \ntext-decoration: underline;'><b>Text to Present</b></a>\n\nOther channels lack support for \nHTML anchor links, but will present a \nURL which is entered in plain text as a link."
    },
    "Emergency Handler": {
        "isEmergencyOn": "Emergency On",
        "emergencyMessageTitle": "Emergency Prompt",
        "emergencyCategoryId": "RingCentral Category"
    },
    "Messages and Prompt": {
        "gqPrompt": "General question prompt",
        "sWelcomeMessage": "Welcome message",
        info: "Configurable bot messages"
    },
    "Business Hours": {
        info: "Working hours of executive for bot to do agent handover"
    }
}

const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

function BotModel(props) {

    const { user } = useTypedSelector((state) => state.authState);

    // console.log(Object.entries(configData))
    // const [apiData, setApiData] = React.useState(apiData);
    const [expanded, setExpanded] = React.useState("panel0");
    const [updateData, setUpdateData] = React.useState({});
    const [env, setEnv] = React.useState('QA');
    const [isLoading, setIsLoading] = useState(false);
    const [apiData, setApiData] = React.useState({});

    // const handleChange = (event) => {
    //   setAge(event.target.value);
    // };days.filter(day => !Object.keys(updateData[key]).includes(day)

    React.useEffect(() => {
        setIsLoading(true)
        getConfigData();

    }, [env])

    function getConfigData() {

        const requestOptions = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + user.token
            },
            body: JSON.stringify({
                "collection": "Config",
                "action": "findone",
                "env": env,
                "query": {
                    "env": "Frontier Air " + env
                },
                "projection": {
                    "token": 0,
                    "chatValidation.apiKey": 0
                }
            })
        };
        fetch(props.APP_API_URL + "/getBotConfig", requestOptions)
            .then(response => response.json())
            .then(data => {
                setApiData(data)               
                setIsLoading(false)
            })
            .catch(error => {
                // console.log(error);
                setIsLoading(false)
            });
    }

    const handleIsAllDayChange = (event, key) => {
        setUpdateData({ ...updateData, [`${key}`]: { isAllDay: event.target.checked } });
    };

    const handleEnvChange = (event) => {
        setEnv(event.target.value);
    };

    const handleDayDelete = (event, key, day) => {
        var data = updateData;
        delete data[key][day]
        setUpdateData({ ...data });
    };

    const handleDayChange = (event) => {
        var data = updateData;
        data.newDay.day = event.target.value;
        setUpdateData({ ...data });
    };


    const handleCategoryDelete = (event, key, val) => {
        var data = updateData;
        data[key] = data[key].filter(item => item != val);
        setUpdateData({ ...data });
    };


    const handleDeleteEmail = (event, key, val) => {
        var data = updateData;
        data[key] = data[key].split(',').filter(item => item != val).join(',');
        setUpdateData({ ...data });
    };

    const handleCategoryAdd = (event, key) => {
        var data = updateData;
        data[key].push(updateData[`${key}_new`]);
        data[`${key}_new`] = ""
        setUpdateData({ ...data });
    };

    const handleEmailAdd = (event, key) => {
        var data = updateData;
        data[key] = `${updateData[key]},${updateData[`${key}_new`]}`;
        data[`${key}_new`] = ""
        setUpdateData({ ...data });
    };


    const handleCatTextChange = (event, key) => {
        var data = updateData;
        data[`${key}_new`] = event.target.value;
        setUpdateData({ ...data });
    };
    const handleAddBzDay = (event, key) => {
        var data = updateData;
        data[key][updateData.newDay.day] = {
            startTime: parseFloat(updateData.newDay.startTime),
            endTime: parseFloat(updateData.newDay.endTime)
        };

        setUpdateData({ ...data, newDay: { day: "None", startTime: 1, endTime: 24 } });
    };
    const handleBroadCastSwitch = (event, key) => {
        setUpdateData({ ...updateData, [`${key}`]: event.target.checked });
    };

    const handleBroadCastMessage = (event, key, pkey) => {
        if (key == "web") {
            var data = updateData;
            data.broadcastMessage[`${pkey}`][`${key}`] = event.target.value 
            // data.broadcastMessage = { ...updateData.broadcastMessage, [`${pkey}`]: { [`${key}`]: event.target.value } };
            setUpdateData({ ...data });
        } else {
            var data = updateData;
            data.broadcastMessage[`${pkey}`][`${key}`] = event.target.value 
            // data.broadcastMessage = { ...updateData.broadcastMessage, [`${pkey}`]: { [`${key}`]: event.target.value } };
            setUpdateData({ ...data });
        }
    };

    const handleTextChange = (event, key, type) => {
        if (type == "number")
            setUpdateData({ ...updateData, [`${key}`]: parseFloat(event.target.value) });
        else
            setUpdateData({ ...updateData, [`${key}`]: event.target.value });
    };

    const handleTimeChange = (event, key) => {
        let data = updateData;
        data.newDay[key] = parseFloat(event.target.value)
        setUpdateData({ ...data });
    };

    const handleEdit = (event, group) => {
        if (group == "Business Hours") {
            let data = { group: group, [`${group}`]: true, newDay: { day: "None", startTime: 1, endTime: 24 } }
            setUpdateData({ ...data, ...apiData["Business Hours"] });
            console.log(updateData)
        } else {
            let data = { group: group, [`${group}`]: true }
            setUpdateData({ ...data, ...apiData[group] });
            console.log(updateData)
        }
    };

    const handleUpdate = (event, group) => {
        console.log("Update Button", updateData)
        setIsLoading(true)
        const requestOptions = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + user.token
            },
            body: JSON.stringify({
                "collection": "Config",
                "action": "updateOne",
                "env": env,
                "query": {
                    "env": "Frontier Air " + env
                    // env: "Frontier Air Dev old"
                },
                "data": updateData
            })
        };
        fetch(props.APP_API_URL + "/updateBotConfig", requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                getConfigData();
                setUpdateData({});
                // setIsLoading(false)
                // // console.log("Formated data", projectData)
            })
            .catch(error => {
                // console.log(error);
                setIsLoading(false)
            });
    };

    const handleCancel = (event, group) => {
        setUpdateData({});
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (

        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <NavBar page={"botconfig"} />

            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 2 }}>
                <Grid container>
                    <Grid item xs={9} md={9}>
                        <Typography variant="h6" component="h6"
                            style={{ fontWeight: 'bold', padding: '10px', color: '#202020' }}>
                            Bot Configurations
                        </Typography>
                    </Grid>
                    <Grid item xs={3} md={3}>
                        <FormControl size="small" style={{ margin: '8px', width: "90%" }}>
                            <InputLabel id="demo-env-select-label">Select Env</InputLabel>
                            <Select
                                style={{ fontSize: "0.85rem", marginTop: "2px" }}
                                labelId="demo-env-select-label"
                                id="demo-env-select"
                                value={env}
                                label="Select Env"
                                onChange={handleEnvChange}
                            >

                                <MenuItem value={"Prod"}>Prod</MenuItem>
                                <MenuItem value={"Dev"}>Dev</MenuItem>
                                <MenuItem value={"QA"}>QA</MenuItem>
                                <MenuItem value={"UAT"}>UAT</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container style={{ marginTop: "10px" }}>
                    <Grid item xs={12} md={12}>
                        <div>
                            {Object.entries(apiData).map(([pkey, value], index) => {
                                return <Accordion expanded={expanded === 'panel' + index} onChange={handleChange('panel' + index)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${index}bh-content`}
                                        id={`panel${index}bh-header`}
                                    >
                                        <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: "bold" }}>
                                            {pkey}
                                        </Typography>
                                        {infoData[pkey] && <Typography sx={{ color: 'text.secondary' }}>{infoData[pkey].info}</Typography>}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableBody>

                                                    <TableRow
                                                        style={{ border: "none" }}
                                                    >
                                                        <TableCell></TableCell>
                                                        <TableCell align='right'>
                                                            {updateData[pkey] ? <Stack spacing={2}
                                                                justifyContent="flex-end"
                                                                direction="row">
                                                                <Button variant="outlined" size="small" onClick={(event) => { handleCancel(event, pkey) }}>
                                                                    Cancel
                                                                </Button>
                                                                <Button variant="outlined" size="small" onClick={(event) => { handleUpdate(event, pkey) }}>
                                                                    Update
                                                                </Button></Stack> : <Button variant="outlined" size="small" onClick={(event) => { handleEdit(event, pkey) }}>
                                                                Edit
                                                            </Button>}

                                                        </TableCell>

                                                    </TableRow>
                                                    {Object.entries(value).map(([key, value]) => (
                                                        <TableRow
                                                            key={key}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row" style={{
                                                                fontWeight: "bold",
                                                                maxWidth: 150,
                                                                whiteSpace: "pre-wrap"
                                                            }}>
                                                                <p>  {infoData[pkey] ? infoData[pkey][key] ? infoData[pkey][key] : key : key}</p>
                                                                <p style={{ fontWeight: "normal" }}>{infoData[pkey] ? infoData[pkey][key] ? infoData[pkey][key + "Info"] : infoData[pkey][key + "Info"] : ""}
                                                                </p>
                                                            </TableCell>
                                                            {pkey == "Broadcast Message" && <TableCell style={{ maxWidth: 250 }} >
                                                                {key == "isBroadcastMessage" && !updateData[pkey] && <Switch disabled checked={value} />}
                                                                {key == "isBroadcastMessage" && updateData[pkey] && <Switch checked={updateData.isBroadcastMessage} onChange={(event) => { handleBroadCastSwitch(event, key) }} />}
                                                                {key == "broadcastMessage" && !updateData[pkey] && <BroadcastMessage value={value} isEdit={false} />}
                                                                {key == "broadcastMessage" && updateData[pkey] && <BroadcastMessage value={updateData[key]} isEdit={updateData[pkey] ? true : false} handleMessage={handleBroadCastMessage} />}
                                                            </TableCell>}
                                                            {pkey == "Emergency Handler" && <TableCell style={{ maxWidth: 250 }} >
                                                                {key == "isEmergencyOn" && !updateData[pkey] && <Switch disabled checked={value} />}
                                                                {key == "isEmergencyOn" && updateData[pkey] && <Switch checked={updateData.isEmergencyOn} onChange={(event) => { handleBroadCastSwitch(event, key) }} />}
                                                                {key == "emergencyMessageTitle" && !updateData[pkey] &&
                                                                    <TextField
                                                                        hiddenLabel
                                                                        id="outlined-size-small"
                                                                        variant='filled'
                                                                        multiline
                                                                        rows={4}
                                                                        disabled
                                                                        fullWidth
                                                                        value={value}
                                                                        size="small"
                                                                    />}
                                                                {key == "emergencyMessageTitle" && updateData[pkey] &&
                                                                    <TextField
                                                                        hiddenLabel
                                                                        id="outlined-size-small"
                                                                        variant='filled'
                                                                        multiline
                                                                        rows={4}
                                                                        fullWidth
                                                                        value={updateData[key]}
                                                                        size="small"
                                                                        onChange={(event) => handleTextChange(event, key, typeof value)}
                                                                    />}
                                                                {key == "emergencyCategoryId" && !updateData[pkey] &&
                                                                    <TextField
                                                                        hiddenLabel
                                                                        id="outlined-size-small"
                                                                        variant='filled'
                                                                        disabled
                                                                        value={value}
                                                                        size="small"
                                                                    />}
                                                                {key == "emergencyCategoryId" && updateData[pkey] &&
                                                                    <TextField
                                                                        hiddenLabel
                                                                        id="outlined-size-small"
                                                                        variant='filled'
                                                                        value={updateData[key]}
                                                                        size="small"
                                                                        onChange={(event) => handleTextChange(event, key, typeof value)}
                                                                    />}
                                                            </TableCell>}
                                                            {pkey == "Counters" && <TableCell style={{ maxWidth: 250 }} >
                                                                {!updateData[pkey] && <TextField
                                                                    hiddenLabel
                                                                    id="outlined-size-small"
                                                                    variant='filled'
                                                                    disabled
                                                                    value={value}
                                                                    size="small"
                                                                />}
                                                                {updateData[pkey] && <TextField
                                                                    hiddenLabel
                                                                    id="outlined-size-small"
                                                                    variant='filled'
                                                                    type={typeof value}
                                                                    value={updateData[key]}
                                                                    size="small"
                                                                    onChange={(event) => handleTextChange(event, key, typeof value)}
                                                                />}
                                                            </TableCell>}

                                                            {pkey == "Messages and Prompt" && <TableCell>
                                                                {!updateData[pkey] && <TextField
                                                                    hiddenLabel
                                                                    id="outlined-size-small"
                                                                    variant='filled'
                                                                    multiline
                                                                    rows={4}
                                                                    disabled
                                                                    fullWidth
                                                                    defaultValue={value}
                                                                    size="small"
                                                                />}
                                                                {updateData[pkey] && <TextField
                                                                    hiddenLabel
                                                                    id="outlined-size-small"
                                                                    variant='filled'
                                                                    multiline
                                                                    rows={4}
                                                                    fullWidth
                                                                    defaultValue={value}
                                                                    size="small"
                                                                />}
                                                            </TableCell>}
                                                            {pkey == "Business Hours" && <TableCell>
                                                                {!updateData[pkey] && <p>isAllDay - <Switch disabled checked={value.isAllDay} /></p>}
                                                                {updateData[pkey] && <p>isAllDay - <Switch checked={updateData[key]?.isAllDay} onChange={(event) => { handleIsAllDayChange(event, key) }} /></p>}
                                                                {!updateData[pkey] && !value.isAllDay && <>
                                                                    {Object.entries(value).map(([day, value]) => (
                                                                        day == "isAllDay" ? <></> :

                                                                            <Chip
                                                                                style={{ margin: "5px" }}
                                                                                label={`${day} ${value.startTime} - ${value.endTime}`}
                                                                            // onClick={handleClick}
                                                                            // onDelete={handleDelete}
                                                                            />
                                                                    ))}
                                                                </>
                                                                }
                                                                {updateData[pkey] && !updateData[key].isAllDay && <>
                                                                    {Object.entries(updateData[key]).map(([day, value]) => (
                                                                        day == "isAllDay" ? <></> :

                                                                            <Chip
                                                                                style={{ margin: "5px" }}
                                                                                label={`${day} ${value.startTime} - ${value.endTime}`}
                                                                                // onClick={handleClick}
                                                                                onDelete={(event) => { handleDayDelete(event, key, day) }}
                                                                            />
                                                                    ))}
                                                                    {Object.keys(updateData[key]).length < 8 &&
                                                                        <Grid container>
                                                                            <Grid item xs={3}>
                                                                                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} size="small">
                                                                                    <InputLabel id="demo-select-small">Days</InputLabel>
                                                                                    <Select
                                                                                        labelId="demo-select-small"
                                                                                        id="demo-select-small"
                                                                                        value={updateData?.newDay?.day}
                                                                                        label="Days"
                                                                                        onChange={handleDayChange}
                                                                                    >
                                                                                        <MenuItem value="None">None</MenuItem>
                                                                                        {days.filter(day => !Object.keys(updateData[key]).includes(day)).map(item => {
                                                                                            return <MenuItem value={item}>{item}</MenuItem>
                                                                                        })}
                                                                                        {/* <MenuItem value={"Sunday"}>Sunday</MenuItem>
                                                                                        <MenuItem value={20}>Monday</MenuItem>
                                                                                        <MenuItem value={30}>Tuesday</MenuItem> */}
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <TextField
                                                                                    sx={{ m: 1, width: 120 }}
                                                                                    size="small"
                                                                                    label="Start Time"
                                                                                    type="number"
                                                                                    variant="standard"
                                                                                    onChange={(event) => handleTimeChange(event, "startTime")}
                                                                                    value={updateData?.newDay?.startTime} />

                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <TextField
                                                                                    sx={{ m: 1, width: 120 }}
                                                                                    size="small"
                                                                                    label="End Time"
                                                                                    type="number"
                                                                                    variant="standard"
                                                                                    onChange={(event) => handleTimeChange(event, "endTime")}
                                                                                    value={updateData?.newDay?.endTime} />

                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <Button style={{ marginTop: "10px" }}
                                                                                    disabled={updateData?.newDay?.day != "None" ? false : true}
                                                                                    variant="outlined" size="small"
                                                                                    onClick={(event) => { handleAddBzDay(event, key) }}>
                                                                                    Add
                                                                                </Button>
                                                                            </Grid>


                                                                        </Grid>
                                                                    }
                                                                </>
                                                                }
                                                            </TableCell>}
                                                            {pkey == "Categories" && <TableCell>
                                                                {!updateData[pkey] && <>
                                                                    {typeof value == "object" ? <div>
                                                                        {value.map((item) => (
                                                                            <Chip
                                                                                style={{ margin: "5px" }}
                                                                                label={`${item}`}
                                                                            // onClick={handleClick}
                                                                            // onDelete={handleDelete}
                                                                            />
                                                                        ))}
                                                                    </div> : <TextField
                                                                        hiddenLabel
                                                                        disabled
                                                                        id="outlined-size-small"
                                                                        variant='filled'
                                                                        fullWidth
                                                                        value={value}
                                                                        size="small"
                                                                    />
                                                                    } </>}
                                                                {updateData[pkey] && <>
                                                                    {typeof updateData[key] == "object" ? <div>
                                                                        {updateData[key].map((item) => (
                                                                            <Chip
                                                                                style={{ margin: "5px" }}
                                                                                label={`${item}`}
                                                                                // onClick={handleClick}
                                                                                onDelete={(event) => { handleCategoryDelete(event, key, item) }}
                                                                            />
                                                                        ))}
                                                                        <Stack direction="row" spacing={2}>
                                                                            <TextField
                                                                                hiddenLabel
                                                                                id="outlined-size-small"
                                                                                variant='filled'
                                                                                fullWidth
                                                                                value={updateData[`${key}_new`]}
                                                                                onChange={(event) => { handleCatTextChange(event, key) }}
                                                                                size="small" />
                                                                            <Button style={{ marginTop: "10px" }}
                                                                                disabled={updateData?.newDay?.day != "None" ? false : true}
                                                                                variant="outlined" size="small"
                                                                                onClick={(event) => { handleCategoryAdd(event, key) }}>
                                                                                Add
                                                                            </Button>
                                                                        </Stack>

                                                                    </div> : <TextField
                                                                        hiddenLabel
                                                                        id="outlined-size-small"
                                                                        variant='filled'
                                                                        fullWidth
                                                                        value={updateData[key]}
                                                                        onChange={(event) => { handleTextChange(event, key, typeof value) }}
                                                                        size="small"
                                                                    />
                                                                    } </>}
                                                            </TableCell>}
                                                            {pkey == "Chat Threshold" && <TableCell>
                                                                {!updateData[pkey] && <>
                                                                    {key == "emailList" ? <>
                                                                        {value.split(',').map((item) => (
                                                                            <Chip
                                                                                style={{ margin: "5px" }}
                                                                                label={`${item}`}
                                                                            // onClick={handleClick}
                                                                            // onDelete={handleDelete}
                                                                            />
                                                                        ))}
                                                                    </> : <TextField
                                                                        hiddenLabel
                                                                        disabled
                                                                        id="outlined-size-small"
                                                                        variant='filled'
                                                                        defaultValue={value}
                                                                        size="small"
                                                                    />}</>
                                                                }
                                                                {updateData[pkey] && <>
                                                                    {key == "emailList" ? <>
                                                                        {updateData[key]?.split(',').map((item) => (
                                                                            <Chip
                                                                                style={{ margin: "5px" }}
                                                                                label={`${item}`}
                                                                                // onClick={handleClick}
                                                                                onDelete={(event) => { handleDeleteEmail(event, key, item) }}
                                                                            />
                                                                        ))}
                                                                        <Stack direction="row" spacing={2}>
                                                                            <TextField
                                                                                hiddenLabel
                                                                                id="outlined-size-small"
                                                                                variant='filled'
                                                                                fullWidth
                                                                                type="email"
                                                                                value={updateData[`${key}_new`]}
                                                                                onChange={(event) => { handleCatTextChange(event, key) }}
                                                                                size="small" />
                                                                            <Button style={{ marginTop: "10px" }}
                                                                                disabled={updateData?.newDay?.day != "None" ? false : true}
                                                                                variant="outlined" size="small"
                                                                                onClick={(event) => { handleEmailAdd(event, key) }}>
                                                                                Add
                                                                            </Button>
                                                                        </Stack>
                                                                    </> : <TextField
                                                                        hiddenLabel
                                                                        id="outlined-size-small"
                                                                        variant='filled'
                                                                        type={typeof value}
                                                                        value={updateData[key]}
                                                                        onChange={(event) => { handleTextChange(event, key, typeof value) }}
                                                                        size="small"
                                                                    />}</>
                                                                }

                                                            </TableCell>}
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>

                            })
                            }
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </Box>

    );
}

// function broadcastMessage(props) {
//     const [editorState, setEditorState] = useState(
//         () => EditorState.createEmpty(),
//     );
//     return (
//         <TableContainer>
//             <Table aria-label="simple table">
//                 <TableBody style={{ backgroundColor: "lightgrey" }}>
//                     {Object.entries(props.value).map(([key, value]) => (
//                         <TableRow
//                             key={key}
//                             sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
//                         >
//                             <TableCell component="th" scope="row">
//                                 {key}
//                             </TableCell>
//                             <TableCell>
//                                 <div>
//                                     {props.isEdit ? <Editor
//                                         editorState={editorState}
//                                         toolbarClassName="toolbarClassName"
//                                         wrapperClassName="wrapperClassName"
//                                         editorClassName="editorClassName"
//                                     // onEditorStateChange={this.onEditorStateChange}
//                                     /> : parse(value.replaceAll('\n', "</br>"))}
//                                 </div>
//                             </TableCell>
//                         </TableRow>
//                     ))}
//                 </TableBody>
//             </Table>
//         </TableContainer>
//     );
// }

export default BotModel;

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import NavBar from '../components/NavBar'
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';

function UnAuth() {


    return (

        <Box sx={{ display: 'flex' }}>
            <CssBaseline />            
            <NavBar page={"unauth"} />

            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 2 }}>
                <Typography variant="h3" component="h3"
                    style={{ fontWeight: 'bold', padding: '10px', color: '#202020' }}>
                    Access Denied
                </Typography>   
                <Typography variant="h6" component="h6"
                    style={{ fontWeight: 'bold', padding: '10px', color: '#202020' }}>
                    Please contact Admin for required access
                </Typography>           

            </Box>

        </Box>

    );
}

export default UnAuth;

import * as React from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import FormatLineSpacingIcon from "@mui/icons-material/FormatLineSpacing";
import ForumIcon from "@mui/icons-material/Forum";
import LineStyleIcon from "@mui/icons-material/LineStyle";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import GrainIcon from "@mui/icons-material/Grain";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Logout from "@mui/icons-material/Logout";
import { Link } from "react-router-dom";

export default function UnAuth(props) {  

  return (
    <div>            
        {props.authenticated && (
          <List>
            <ListSubheader>
              <b>{`User`}</b>
            </ListSubheader>
            <ListItem button>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary={props.user.name} />
            </ListItem>
            <ListItem button onClick={() => props.logout()}>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText primary={"Logout"} />
            </ListItem>
          </List>
        )}
    </div>
  );
}

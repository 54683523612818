import "./index.css";
import "@aws-amplify/ui-react/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import ReactDOM from "react-dom";

import { Amplify } from "@aws-amplify/core";
import { AmplifyProvider } from "@aws-amplify/ui-react";

import config from "./aws-exports";
import reportWebVitals from "./reportWebVitals";
import Router from "./Routes";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";

Amplify.configure(config);

ReactDOM.render(
  <React.StrictMode>
    <AmplifyProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </AmplifyProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

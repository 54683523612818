import { withAuthenticator } from "@aws-amplify/ui-react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  autoLoginAction,
  loginUserAction,
} from "./redux/Authorization/Authorization.action";
import { useTypedSelector } from "./redux/root.reducer";
import Router from "./Routes";

const App = (props) => {
  const { initialized } = useTypedSelector((state) => state.authState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (initialized && props.user) {
      dispatch(loginUserAction());
    }
  }, [props.user]);

  useEffect(() => {
    dispatch(autoLoginAction());
  }, []);

  return initialized && <Router />;
};

export default withAuthenticator(App);

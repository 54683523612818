import React, { useState } from 'react';
import Box from '@mui/material/Box';
import NavBar from '../components/NavBar'
import CssBaseline from '@mui/material/CssBaseline';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Metric from '../components/units/Metric'
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useTypedSelector } from "../redux/root.reducer";
import UnAuth from '../pages/UnAuth';

const BoData = [
    {
        title: "Users",
        today: 100,
        yesterday: 90
    },
    {
        title: "Repeat Users",
        today: 2,
        yesterday: 3
    },
    {
        title: "Conversations",
        today: 80,
        yesterday: 70
    },

    {
        title: "Handover",
        today: 90,
        yesterday: 80
    },
    {
        title: "FAQ",
        today: "30",
        yesterday: "40"
    },

]

const OpsData = [
    {
        title: "Messages",
        today: "2K",
        yesterday: "3.5K"
    },
    {
        title: "Intents",
        today: 10,
        yesterday: 20
    },
    {
        title: "Goals Completed",
        today: 70,
        yesterday: 80
    }
]

const BotData = [
    {
        title: "Self Service",
        today: 10,
        yesterday: 20
    },
    {
        title: "Missed Utterances",
        today: 70,
        yesterday: 80
    },
    {
        title: "Intent Score",
        today: 91,
        yesterday: 92
    }
]

function Home() {
    const { user } = useTypedSelector((state) => state.authState);


    return(
        
                <Box sx={{ display: 'flex' }}>
                    {user && user.roles ? <>
                    <CssBaseline />
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={false}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <NavBar page={"home"} />

                    <Box
                        component="main"
                        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 2 }}>
                        <Typography variant="h6" component="h6"
                            style={{ fontWeight: 'bold', padding: '10px', color: '#202020' }}>
                            Home
                        </Typography>

                        <Box sx={{ marginTop: '20px' }}>
                            <Typography variant="subtitle1" style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
                                Business Objective
                            </Typography>
                            <Divider />
                            <Box sx={{ marginTop: '20px', marginBottom: '20px' }}>
                                <Grid container spacing={2}>
                                    {
                                        BoData && BoData.map((item, index) => {
                                            return <Grid key={`hm${index}`} item xs={4}>
                                                <Metric data={item} />
                                            </Grid>
                                        })
                                    }
                                </Grid>
                            </Box>
                            <Typography variant="subtitle1" style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
                                Operational
                            </Typography>
                            <Divider />
                            <Box sx={{ marginTop: '20px', marginBottom: '20px' }}>

                                <Grid container spacing={2}>
                                    {
                                        OpsData && OpsData.map((item, index) => {
                                            return <Grid key={`hm${index}`} item xs={4}>
                                                <Metric data={item} />
                                            </Grid>
                                        })
                                    }
                                </Grid>
                            </Box>
                            <Typography variant="subtitle1" style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
                                Bot Performance
                            </Typography>
                            <Divider />
                            <Box sx={{ marginTop: '20px', marginBottom: '20px' }}>

                                <Grid container spacing={2}>
                                    {
                                        BotData && BotData.map((item, index) => {
                                            return <Grid key={`hm${index}`} item xs={4}>
                                                <Metric data={item} />
                                            </Grid>
                                        })
                                    }
                                </Grid>
                            </Box>

                        </Box>

                    </Box>
                    </> : <UnAuth /> }
                </Box>         
        );
}

export default Home;

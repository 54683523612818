import * as React from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import FormatLineSpacingIcon from "@mui/icons-material/FormatLineSpacing";
import ForumIcon from "@mui/icons-material/Forum";
import LineStyleIcon from "@mui/icons-material/LineStyle";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import GrainIcon from "@mui/icons-material/Grain";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Logout from "@mui/icons-material/Logout";
import { Link } from "react-router-dom";

export default function Developer(props) {  

  return (
    <div>
      <List>
          <ListSubheader>
            <b>{`Dashboard`}</b>
          </ListSubheader>
          {/* <Link style={{ display: "contents" }} to="/home">
            <ListItem button selected={props.params.page === "home"}>
              <ListItemIcon>
                <LineStyleIcon />
              </ListItemIcon>
              <ListItemText primary={"Home"} />
            </ListItem>
          </Link> */}
          <Link to="/analytics" style={{ display: "contents" }}>
            <ListItem button selected={props.params.page === "analytics"}>
              <ListItemIcon>
                <AutoGraphIcon />
              </ListItemIcon>
              <ListItemText primary={"Analytics"} />
            </ListItem>
          </Link>
        </List>
        <Divider />
      <List>
        <ListSubheader>
          <b>{`Utilities`}</b>
        </ListSubheader>
        <Link style={{ display: "contents" }} to="/botconfig">
          <ListItem button selected={props.params.page === "botconfig"}>
            <ListItemIcon>
              <GrainIcon />
            </ListItemIcon>
            <ListItemText primary={"Bot Configurations"} />
          </ListItem>
        </Link>
        <Link style={{ display: "contents" }} to="/botresponses">
          <ListItem button selected={props.params.page === "botresponses"}>
            <ListItemIcon>
              <SettingsApplicationsIcon />
            </ListItemIcon>
            <ListItemText primary={"Bot Responses"} />
          </ListItem>
        </Link>
      </List>
      <Divider />
        <List>
          <ListSubheader>
            <b>{`Testing`}</b>
          </ListSubheader>
          <Link style={{ display: "contents" }} to="/botaccuracy">
            <ListItem button selected={props.params.page === "botaccuracy"}>
              <ListItemIcon>
                <FormatLineSpacingIcon />
              </ListItemIcon>
              <ListItemText primary={"Accuracy Test"} />
            </ListItem>
          </Link>
          <Link to="/conversation" style={{ display: "contents" }}>
            <ListItem button selected={props.params.page === "conversation"}>
              <ListItemIcon>
                <ForumIcon />
              </ListItemIcon>
              <ListItemText primary={"Conversation Test"} />
            </ListItem>
          </Link>
        </List>
        <Divider />
        {props.authenticated && (
          <List>
            <ListSubheader>
              <b>{`User`}</b>
            </ListSubheader>
            <ListItem button>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary={props.user.name} />
            </ListItem>
            <ListItem button onClick={() => props.logout()}>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText primary={"Logout"} />
            </ListItem>
          </List>
        )}
    </div>
  );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Form from 'react-bootstrap/Form';
import Button from '@mui/material/Button';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function BotInputs(props) {

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <Grid container spacing={"5"}>
                <Grid item xs={3} md={3}>
                    <FormControl size="small" fullWidth style={{ margin: '8px' }}>
                        <InputLabel id="demo-simple-select-label">Select Environment</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.formData.env}
                            disabled={props.user.env ? true : false}
                            label="Select Environment"
                            onChange={props.handlerEnvSelection}
                        >
                            {props.user.env && <MenuItem value={props.user.env}>{props.user.env}</MenuItem>}
                            {!props.user.env && <MenuItem value={"Select Environment"} disabled>Select Environment</MenuItem>}
                            {!props.user.env && <MenuItem value={"app.cognigy"}>App.Cognigy</MenuItem>}
                            {!props.user.env && <MenuItem value={"frontier"}>Frontier</MenuItem>}
                            {!props.user.env && <MenuItem value={"frontier-dev"}>Frontier-dev</MenuItem>}
                            {!props.user.env && <MenuItem value={"fpshr"}>FPSHr</MenuItem>}

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3} md={3}>
                    <FormControl size="small" fullWidth style={{ margin: '8px' }}>
                        <InputLabel id="demo-simple-select-label">Select Bot Agent</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.formData.projectId}
                            label="Select Bot Agent"
                            onChange={props.handleAgentSelection}
                        >
                            <MenuItem value={"Select Bot Agent"} disabled>Select Bot Agent</MenuItem>
                            {props.projects && props.projects.map((item, index) => { return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem> })}
                            {/* <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem> */}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    {/* <Form.File id="exampleFormControlFile1"
                // onChange={onFileChange}
                label="Utterance sheet input" /> */}
                    <FormControl size="small" fullWidth style={{ width: '98%' }}>
                        <TextField
                            style={{ width: '100%' }}
                            label="Test file"
                            id="outlined-size-small"
                            defaultValue=""
                            type="file"
                            onChange={props.onFileChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            size="small"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3} style={{ marginTop: '10px', padding: '5px' }}>
                    <Button href="#text-buttons">Sample Testing Sheet</Button>
                </Grid>
                <Grid item xs={6} md={6}>
                    <Button variant="contained"
                        style={{ margin: "8px", textTransform: 'capitalize' }}
                        onClick={props.onSubmit}
                        size="small">
                        Get Result
                    </Button>
                </Grid>
                <Grid item xs={6} md={6} style={{ textAlign: "right" }}>
                   { props.downData && props.downData.length > 0 && <Button variant="contained" size="small" style={{ textTransform: 'capitalize' }}
                        onClick={props.downloadClick}>
                        Download Report
                    </Button>}
                </Grid>

            </Grid>
            <div>


            </div>
        </Box>
    );
}

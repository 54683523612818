import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

export default function AccuracyTables(props) {
  // console.log("Props", props)
  return (
    <Box style={{ marginTop: '20px' }}>
      <Typography variant="h6" component="h6"
        style={{ fontWeight: 'bold', padding: '10px', color: '#202020', fontSize: '15px' }}>
        {props.details && props.details.title}
      </Typography>
      <TableContainer>
        <Table sx={{ minWidth: '650px' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {props.details && props.details.header.map((head, index) => (
                <Tooltip title={head.descr} arrow>
                  <TableCell key={index} style={index !== 0 ? { fontWeight: 'bold', textAlign: 'center' } : { fontWeight: 'bold' }}>{head.name}</TableCell>
                </Tooltip>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.details && props.details.data.map((row, pindex) => (
              <TableRow
                key={`tr${pindex}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                style={row.f1Score > 70 ? { backgroundColor: 'lightgreen' } : row.f1Score < 60 ?
                  { backgroundColor: '#ff8080' } : {}}
              >
                {props.details.header && props.details.header.map((head, index) => (
                  <TableCell style={index !== 0 ? { textAlign: 'center' } : {}} scope="row">
                    {row[head.id]}
                  </TableCell>
                ))}
              </TableRow>
            ))}

          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

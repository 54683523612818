import React, { useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useTypedSelector } from "./redux/root.reducer";

const pages = {
  user: ["/analytics", "/botconfig"],
  developer: ["/analytics", "/home",
    "/botaccuracy",
    "/conversation",
    "/botresponses",
    "/botconfig"],
}



const ProtectedRoute = () => {
  const { user } = useTypedSelector((state) => state.authState);
  if (user) {
    if (user.roles === "admin")
      return <Outlet />;
    else if (user.roles === "developer" && pages.developer.indexOf(window.location.pathname) !== -1)
      return <Outlet />
    else if (user.roles === "user" && pages.user.indexOf(window.location.pathname) !== -1)
      return <Outlet />
    else
      return <Navigate to="/unauth" />
  } else {
    return <Navigate to="/" />
  }

}

export default ProtectedRoute
import React, { useState } from 'react';
import XLSX from 'xlsx';
import saveAs from 'save-as'
import BotInputs from '../components/BotInputs'
import Box from '@mui/material/Box';
import NavBar from '../components/NavBar'
import AccuracyTables from '../components/AccuracyTables'
import ConfusionMatrix from '../components/ConfusionMatrix'
import AccuracyTiles from '../components/AccuracyTiles'
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { useTypedSelector } from "../redux/root.reducer";

const APP_API_URL = "https://api.botomatic.ai";

function BotAccuracy(props) {

    const [accuracyData, setAccuracyData] = useState({});
    const { user } = useTypedSelector((state) => state.authState);
    const [convData, setConvData] = useState([]);
    const [formData, setFormData] = useState({ env: user.env, userId: user.email, agentName: "", data: "" });
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [projects, setProjects] = useState([]);
    const [alertMessage, setAlertMessage] = useState("Something went wrong. Please try again later.");

    const handlerEnvSelection = event => {
        setFormData({
            ...formData,
            env: event.target.value
        })
        getAllAgent(event.target.value)

    }

    React.useEffect(() => {
        getAllAgent();
    }, [])

    const getAllAgent = (env) => {
        if (user.env) {
            env = user.env
        }
        const requestOptions = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + user.token
            },
            body: JSON.stringify({
                "env": env
            })
        };
        fetch(props.APP_API_URL + "/agentlist", requestOptions)
            .then(response => response.json())
            .then(data => {
                // console.log("ProjectsUrl result", data);
                const projectData = data._embedded.projects.map(proj => { return { name: proj.name, id: proj._links.self.href.split('projects/')[1] } });
                setProjects([...projectData]);
                // console.log("Formated data", projectData)
            })
            .catch(error => {
                // console.log(error);
                setIsLoading(false);
                if (env) {
                    setAlertMessage("Something went wrong. Please try again later.")
                    setShow(true);
                }
            });

    }

    const handleAgentSelection = event => {
        setShow(false);
        const requestOptions = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + user.token
            },
            body: JSON.stringify({
                "env": formData.env,
                "id": event.target.value
            })
        };
        let agentName = projects.find(item => item.id === event.target.value)
        fetch(props.APP_API_URL + "/agentendpoints", requestOptions)
            .then(response => response.json())
            .then(data => {
                // console.log("EndpointsUrl result", data);
                const endpointData = data._embedded.endpoints.filter(item => item.channel === 'rest' && item.name.toLowerCase().indexOf("nlu") >= 0);
                if (endpointData.length > 0) {
                    // setProjects(projectData);
                    // console.log("endpointData data", endpointData)
                    // console.log(event.target.value);
                    // console.log("Agent Name", agentName);
                    setFormData({
                        ...formData,
                        projectId: event.target.value,
                        URLToken: endpointData[0].URLToken,
                        agentName: agentName.name
                    });
                } else {
                    setAlertMessage("No Rest endpoint associate with this Project")
                    setShow(true);
                }
            })
            .catch(error => {
                // console.log(error);
                setIsLoading(false);
                setAlertMessage("Something went wrong. Please try again later.")
                setShow(true);
            });
    }
    const handleUserIdChange = event => {
        // console.log(event.target.value);
        setFormData({ ...formData, userId: event.target.value });
    }

    const downloadClick = event => {
        // XLSX = require('xlsx');
        try {
            var wb = XLSX.utils.book_new();

            var accuracyScores = [];
            var confusionMatrix = [];
            var missedClassified = [];

            accuracyData.accuracyScores.data.map(item => {
                accuracyScores.push({
                    Intent: item.intent,
                    Identified: item.identified,
                    "Correctly Identified": item.correctlyIdentified,
                    Expected: item.expected,
                    Recall: item.recall,
                    Precision: item.precision,
                    "F1 Score": item.f1Score
                })

                var intentsValues = {}
                item.confusionData.map(element => {
                    intentsValues = { ...intentsValues, ...element }
                })
                confusionMatrix.push({
                    "Expected↓ Identified→": item.intent,
                    ...intentsValues
                })

                item.missedClassified.map(missData => {
                    let data = Object.entries(missData);
                    missedClassified.push({ missedClassified: `Expected ${item.intent} but identified ${data[0][0]}` });
                    let utterances = data[0][1].map(utter => { return { missedClassified: utter } });
                    missedClassified = [...missedClassified, ...utterances, ...[{ missedClassified: "" }]]
                })
            })

            const botAccuracy = {
                [accuracyData.botAccuracy[0].title]: accuracyData.botAccuracy[0].message,
                [accuracyData.botAccuracy[1].title]: accuracyData.botAccuracy[1].message

            }

            // console.log(botAccuracy)

            var botAccuracyData = XLSX.utils.json_to_sheet([botAccuracy]);
            var accurayScoreData = XLSX.utils.json_to_sheet(accuracyScores);
            var confusionMatrixData = XLSX.utils.json_to_sheet(confusionMatrix);
            var detailedData = XLSX.utils.json_to_sheet(accuracyData.rawData);
            var missedClassifiedData = XLSX.utils.json_to_sheet(missedClassified, { skipHeader: true });

            XLSX.utils.book_append_sheet(wb, botAccuracyData, "Bot Accuracy");
            XLSX.utils.book_append_sheet(wb, accurayScoreData, "Intent Score");
            XLSX.utils.book_append_sheet(wb, confusionMatrixData, "Confusion Matrix");
            XLSX.utils.book_append_sheet(wb, detailedData, "Details");
            XLSX.utils.book_append_sheet(wb, missedClassifiedData, "Missed Classified");

            var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${formData.userId}-${new Date().getTime()}.xlsx`);
        } catch (error) {
            setAlertMessage("Something went wrong. Please try again later.")
            setShow(true)
        }
    };

    const onSubmit = event => {
        setIsLoading(true);
        setShow(false);
        // console.log("formData", formData)
        if (formData.projectId != "--Select Bot--" &&
            formData.userId.trim() != '' &&
            formData.data.length > 0) {
            // console.log("formData", formData)
            const requestOptions = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + user.token
                },
                body: JSON.stringify(formData)
            };
            fetch(props.APP_API_URL + '/agenttest', requestOptions)
                .then(response => response.json())
                .then(data => { // console.log("Api result", data); 
                    setAccuracyData(data); setIsLoading(false);
                })
                .catch(error => {
                    // console.log(error);
                    setIsLoading(false);
                    setAlertMessage("Something went wrong. Please try again later.")
                    setShow(true);
                });
        } else {
            setAlertMessage("Please select bot, user id and utterance file")
            setShow(true);
            setIsLoading(false);
        }
    }

    const onFileChange = event => {
        setIsLoading(true);
        setShow(false)

        try {
            const reader = new FileReader();
            if (reader.readAsBinaryString) {
                reader.onload = (event) => {
                    const bstr = event.target.result;
                    const workbook = XLSX.read(bstr, { type: 'binary' });
                    const firstSheet = workbook.SheetNames[0];
                    const excelRows = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
                    if (Array.isArray(excelRows) && excelRows.length > 0) {
                        let row = excelRows[0]
                        if (row.hasOwnProperty("utterance") && row.hasOwnProperty("expectedIntent")) {
                            setFormData({ ...formData, data: excelRows });
                        } else if (row.hasOwnProperty("Case") && row.hasOwnProperty("UserInput")) {
                            var totalConversations = []
                            var CaseData = {}
                            excelRows.map((item, index) => {
                                let convData = { ...item };
                                if (item.hasOwnProperty("Case")) {
                                    if (index > 0)
                                        totalConversations.push(CaseData);
                                    CaseData.Case = item.Case
                                    CaseData.data = [];

                                    let ResponseData = {
                                        UserInput: item.UserInput, Response: [],
                                        Intent: item.Intent
                                    };
                                    ResponseData.Response.push({
                                        ResponseType: item.ResponseType,
                                        Response: item.Response
                                    })
                                    CaseData.data.push(ResponseData);

                                } else if (item.hasOwnProperty("UserInput")) {
                                    let ResponseData = {
                                        UserInput: item.UserInput, Response: [],
                                        Intent: item.Intent
                                    };
                                    ResponseData.Response.push({ ResponseType: item.ResponseType, Response: item.Response });
                                    CaseData.data.push(ResponseData);
                                } else {
                                    if (item.ResponseType.toUpperCase() === "QUICKREPLY")
                                        CaseData.data[CaseData.data.length - 1].Response[0].buttons = item.Response;
                                    else
                                        CaseData.data[CaseData.data.length - 1].Response.push(item);
                                }
                                if (index === excelRows.length - 1)
                                    totalConversations.push(CaseData);
                            })
                            setFormData({ ...formData, data: totalConversations });
                        } else {
                            setAlertMessage("Excel sheet dont't have 'utterance' and 'expectedIntent column. Please make sure it matches cases")
                            setShow(true);
                        }
                    } else {
                        setAlertMessage("No data found in excel")
                        setShow(true);
                    }
                    setIsLoading(false);
                    // console.log(excelRows);
                };
                if (event.target.files[0])
                    reader.readAsBinaryString(event.target.files[0]);
                else
                    setIsLoading(false);

            }
        } catch (error) {
            // console.log(error)
            setAlertMessage("Something went wrong. Please try again later.")
            setIsLoading(false);
            setShow(true)
        }
        // Update the state
        // this.setState({ selectedFile: event.target.files[0] });
        //     // console.log("selectedFile", event.target.files[0])
        //     var workbook = XLSX.readFile(event.target.files[0].name);
        // var sheet_name_list = workbook.SheetNames;
        // // console.log(XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list[0]]))

    };

    return (

        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <NavBar page={"botaccuracy"} />

            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 2 }}>
                <Typography variant="h6" component="h6"
                    style={{ fontWeight: 'bold', padding: '10px', color: '#202020' }}>
                    Bot Accuracy Testing
                </Typography>
                <Collapse in={show}>
                    <Alert action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setShow(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    } severity="error"><b>Error: </b>{alertMessage}</Alert>
                </Collapse>
                <BotInputs
                    user={user}
                    projects={projects}
                    handleAgentSelection={handleAgentSelection}
                    handlerEnvSelection={handlerEnvSelection}
                    formData={formData}
                    onFileChange={onFileChange}
                    onSubmit={onSubmit}
                    handleUserIdChange={handleUserIdChange} />

                {accuracyData.botAccuracy &&
                    <AccuracyTiles data={accuracyData.botAccuracy} onSubmit={downloadClick} />}
                <AccuracyTables
                    details={accuracyData.accuracyScores}
                />
                {accuracyData.accuracyScores &&
                    <ConfusionMatrix
                        details={accuracyData.accuracyScores}
                    />}
            </Box>
        </Box>

    );
}

export default BotAccuracy;

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import NavBar from '../components/NavBar'
import CssBaseline from '@mui/material/CssBaseline';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import Button from '@mui/material/Button';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useTypedSelector } from "../redux/root.reducer";
import { getFormGroupUtilityClass } from '@mui/material';

const headers = [
    { id: "name", value: "Name" },
    { id: "email", value: "Email" },
    { id: "phone_number", value: "Phone number" },
    { id: "action", value: "Action" }
]

const editHeader = [
    { id: "name", value: "Name" },
    { id: "email", value: "Email" },
    { id: "phone_number", value: "Phone number" },
    { id: "group", value: "Group" },
    { id: "newgroup", value: "New Group" }
]

export default function UserManagement(props) {
    // console.log("Props", props)
    const [usersData, setUsersData] = React.useState([]);
    const [groupsData, setGroupsData] = React.useState([]);
    const [groupName, setGroupName] = React.useState("Select Group");
    const [newGroupName, setNewGroupName] = React.useState("Select Group");
    const [filterUsers, setFilterUsers] = React.useState([]);
    const [searchText, setSearchText] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [editUser, setEditUser] = React.useState({});
    const [delUser, setDelUser] = React.useState({});
    const [userGroup, setUserGroup] = React.useState("No Access");
    const [open, setOpen] = React.useState(false);
    const [delopen, setDelOpen] = React.useState(false);
    const [delUserEmail, setDelUserEmail] = React.useState("");
    const { user } = useTypedSelector((state) => state.authState);

    React.useEffect(() => {
        setFilterUsers([])
        setGroupName("Select Group");
        setNewGroupName("Select Group")
        setSearchText("")
        setEditUser({})
        setUserGroup("No Access")
        if (props.page === "users") {
            getUsers("/getUsers");
        } else {
            getGroups()
        }

    }, [props.page])

    const getUsers = (path) => {
        setIsLoading(true);

        const requestOptions = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + user.token
            }
        };
        fetch(props.APP_API_URL + path, requestOptions)
            .then(response => response.json())
            .then(data => {
                // console.log("ProjectsUrl result", data);
                // const projectData = data._embedded.projects.map(proj => { return { name: proj.name, id: proj._links.self.href.split('projects/')[1] } });
                setUsersData(data);
                setFilterUsers(data);
                setIsLoading(false);
                // console.log("Formated data", projectData)
            })
            .catch(error => {
                // console.log(error);
                setIsLoading(false);
                // if (env) {
                //     setAlertMessage("Something went wrong. Please try again later.")
                //     setShow(true);
                // }
            });
    }

    const getGroups = () => {
        setIsLoading(true);
        const requestOptions = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + user.token
            }
        };
        fetch(props.APP_API_URL + "/getGroups", requestOptions)
            .then(response => response.json())
            .then(data => {
                setGroupsData(data.Groups);
                if (props.page === "groups")
                    if (data.Groups && data.Groups.length > 0) {
                        setGroupName(data.Groups[0].GroupName)
                        setUserGroup(data.Groups[0].GroupName);
                        getUsers("/getGroupUsers?group=" + data.Groups[0].GroupName)
                    }
                setIsLoading(false);
            })
            .catch(error => {
                // console.log(error);
                setIsLoading(false);
                // if (env) {
                //     setAlertMessage("Something went wrong. Please try again later.")
                //     setShow(true);
                // }
            });
    }

    const getUserGroup = (userName) => {
        setIsLoading(true);
        const requestOptions = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + user.token
            }
        };
        fetch(props.APP_API_URL + "/getUserGroup?userName=" + userName, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.Groups && data.Groups.length > 0)
                    setUserGroup(data.Groups[0].GroupName);
                else
                    setUserGroup("No Access");
                setIsLoading(false);
            })
            .catch(error => {
                // console.log(error);
                setIsLoading(false);
                // if (env) {
                //     setAlertMessage("Something went wrong. Please try again later.")
                //     setShow(true);
                // }
            });
    }

    const handleUpdateUser = () => {

        setIsLoading(true);
        const requestOptions = {
            method: userGroup === "No Access" ? 'put' : 'post',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + user.token
            },
            body: JSON.stringify({
                userName: editUser.Username,
                toRemoveGroupName: userGroup,
                toAddGroupName: newGroupName
            })
        };

        fetch(props.APP_API_URL + "/userGroup", requestOptions)
            .then(response => response.json())
            .then(data => {
                getUserGroup(editUser.Username)
                if (props.page === "groups")
                    getUsers("/getGroupUsers?group=" + groupName)
                setNewGroupName("Select Group")
                setIsLoading(false);
            })
            .catch(error => {
                // console.log(error);
                setIsLoading(false);
            });


    }

    const handlerGrpSelection = (event) => {
        setGroupName(event.target.value)
        getUsers("/getGroupUsers?group=" + event.target.value)
    }

    const handlerNewGrpSelection = (event) => {
        setNewGroupName(event.target.value)
    }

    const handleClickOpen = (edituser) => {
        if (props.page === "users") {
            getUserGroup(edituser.Username)
            getGroups()
        }
        setEditUser(edituser);
        setOpen(true);
    };

    const handleClose = () => {
        setEditUser({})
        setUserGroup("No Access")
        setOpen(false);
    };

    const handleDelClickOpen = (user) => {
        setDelUser(user);
        setDelOpen(true);
    };

    const handleDelClose = () => {
        setDelUser({})
        setDelUserEmail("")
        setDelOpen(false);
    };

    const handleRefreshBtn = () => {
        if (props.page === "groups")
            getUsers("/getGroupUsers?group=" + groupName)
        else
            getUsers("/getUsers");
    }

    const handleEmailId = (event) => {
        setDelUserEmail(event.target.value);
    };

    const handleDeleteUser = () => {
        setIsLoading(true);
        const requestOptions = {
            method: 'delete',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + user.token
            },
            body: JSON.stringify({
                userName: delUser.Username
            })
        };

        fetch(props.APP_API_URL + "/bmUser", requestOptions)
            .then(response => response.json())
            .then(data => {
                if (props.page === "groups")
                    getUsers("/getGroupUsers?group=" + groupName);
                else
                    getUsers("/getUsers");
                setIsLoading(false);
                setDelOpen(false);
            })
            .catch(error => {
                // console.log(error);
                setIsLoading(false);
            });

    }

    const handleSearchKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (searchText.trim() === "") {
                setFilterUsers(usersData);
            } else {
                const filterData = usersData.filter(user =>
                    user.name.indexOf(searchText) !== -1 || user.email.indexOf(searchText) !== -1);
                setFilterUsers(filterData)
            }
            console.log('do validate', searchText)
        }
    }

    const handleSearchChange = event => {
        // console.log(event.target.value);
        setSearchText(event.target.value);
    }

    return (

        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <NavBar page={props.page} />
            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 2 }}>
                <Typography variant="h6" component="h6"
                    style={{ fontWeight: 'bold', padding: '10px', color: '#202020' }}>
                    {props.title}
                </Typography>
                <Box style={{ marginTop: '20px' }}>
                    <Grid container spacing={"5"}>
                        {props.page === "groups" &&
                            <Grid item xs={3} md={3}>
                                <FormControl size="small" fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select Group</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={groupName}
                                        label="Select Group"
                                        onChange={handlerGrpSelection}
                                    >
                                        <MenuItem value={"Select Group"} disabled>Select Group</MenuItem>
                                        {groupsData && groupsData.map(item => {
                                            return <MenuItem value={item.GroupName} >{item.GroupName}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>}
                        <Grid item xs={3} md={3}>
                            <TextField
                                label="Search"
                                fullWidth
                                hiddenLabel
                                id="outlined-size-small"
                                defaultValue=""
                                onChange={handleSearchChange}
                                onKeyDown={handleSearchKeyDown}
                                size="small"
                            />
                        </Grid>
                        {props.page !== "groups" &&
                            <Grid item xs={3} md={3}>

                            </Grid>
                        }
                        <Grid item xs={3} md={3}>

                        </Grid>
                        <Grid item xs={3} md={3} style={{ textAlign: "end" }}>
                            <Button 
                                variant="outlined" 
                                onClick={handleRefreshBtn}
                                size="small">
                                Refresh
                            </Button>
                        </Grid>
                    </Grid>

                    <TableContainer>
                        <Table sx={{ minWidth: '650px' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {headers && headers.map((head, index) => (
                                        <TableCell key={`thuser${index}`} style={{ fontWeight: 'bold' }}>{head.value}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filterUsers && filterUsers.map((row, pindex) => (
                                    <TableRow
                                        key={`tr${pindex}`}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        {headers && headers.map((head, index) => (
                                            head.id === "action" ?
                                                <TableCell key={`tract${index}`} scope="row">
                                                    <Button key={`trbtnedt${index}`} variant="outlined" size="small"
                                                        onClick={() => handleClickOpen(row)}>
                                                        Edit
                                                    </Button>
                                                    <span>   </span>
                                                    <Button key={`trbtndel${index}`} variant="outlined" size="small" color="error"
                                                        onClick={() => handleDelClickOpen(row)}>
                                                        Delete
                                                    </Button>
                                                </TableCell> :
                                                <TableCell key={`trdata${index}`} scope="row">
                                                    {head.id === "email" ? (row.email_verified == "true") ? <CheckCircleOutlineRoundedIcon key={`tdemilverfy${index}`} color='success' /> : <HighlightOffRoundedIcon key={`tdemailnovfyact${index}`} color="error" /> : ""}
                                                    {"  " + row[head.id]}
                                                </TableCell>
                                        ))}
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Dialog open={open}>
                        <DialogTitle>Edit User</DialogTitle>
                        <DialogContent>
                            <TableContainer>
                                <Table sx={{ minWidth: '500px' }} aria-label="simple table">
                                    <TableBody>
                                        {editHeader && editHeader.map((row, pindex) => (
                                            <TableRow
                                                key={`tr${pindex}`}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell key={`thdiauser${pindex}`} style={{ fontWeight: 'bold' }}>{row.value}</TableCell>
                                                <TableCell key={`trdiadata${pindex}`} scope="row">
                                                    {row.id !== "group" && row.id !== "newgroup" ? editUser[row.id] + "  " : ""}
                                                    {row.id === "email" ? (editUser.email_verified == "true") ? <CheckCircleOutlineRoundedIcon key={`tddiaemilverfy${pindex}`} color='success' /> : <HighlightOffRoundedIcon key={`tddiaemailnovfyact${pindex}`} color="error" /> : ""}
                                                    {row.id === "group" ? props.page === "groups" ? groupName : isLoading ? <CircularProgress color="inherit" /> : userGroup : ""}
                                                    {row.id === "newgroup" && <FormControl size="small" fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Select Group</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={newGroupName}
                                                            label="Select Group"
                                                            onChange={handlerNewGrpSelection}
                                                        >
                                                            <MenuItem value={"Select Group"} disabled>Select Group</MenuItem>
                                                            {groupsData && groupsData.filter(ele => ele.GroupName !== userGroup || userGroup === "").map(item => {
                                                                return <MenuItem value={item.GroupName} >{item.GroupName}</MenuItem>
                                                            })}
                                                            <MenuItem value={"No Access"} >No Access</MenuItem>
                                                        </Select>
                                                    </FormControl>}
                                                </TableCell>

                                            </TableRow>
                                        ))}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleUpdateUser} disabled={isLoading || userGroup === newGroupName}>Update</Button>
                            <Button onClick={handleClose} disabled={isLoading}>Close</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={delopen}>
                        <DialogTitle>Delete User</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Do you want to delete the user <b>{delUser.name}</b>? To confirm, please re-type the User email.
                            </DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Email Address"
                                type="email"
                                onChange={handleEmailId}
                                fullWidth
                                variant="standard"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDelClose}>Cancel</Button>
                            <Button onClick={handleDeleteUser}
                                disabled={delUserEmail !== delUser.email}
                                variant="contained" color="error">Delete</Button>
                        </DialogActions>
                    </Dialog>

                </Box>
            </Box>
        </Box>
    );
}

import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import NavBar from '../components/NavBar'
import CssBaseline from '@mui/material/CssBaseline';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Metric from '../components/units/Metric'
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Highcharts from 'highcharts';
// import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import HighchartsReact from 'highcharts-react-official'
import Tile from '../components/units/Tile'
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Today from '@mui/icons-material/Today';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { useTypedSelector } from "../redux/root.reducer";
import AccuracyTables from '../components/AccuracyTables'
import XLSX from 'xlsx';
import saveAs from 'save-as'

// Load the exporting module.
import Exporting from 'highcharts/modules/exporting';
import Wordcloud from 'highcharts/modules/wordcloud';
import Sankey from 'highcharts/modules/sankey';
import { Divider } from '@mui/material';
import moment from 'moment-timezone'
import UnAuth from '../pages/UnAuth';

// Initialize exporting module.
Exporting(Highcharts);
Wordcloud(Highcharts)
Sankey(Highcharts)


var abbrs = {
    EST: 'Eastern Standard Time',
    EDT: 'Eastern Daylight Time',
    CST: 'Central Standard Time',
    CDT: 'Central Daylight Time',
    MST: 'Mountain Standard Time',
    MDT: 'Mountain Daylight Time',
    PST: 'Pacific Standard Time',
    PDT: 'Pacific Daylight Time',
};

moment.fn.zoneName = function () {
    var abbr = this.zoneAbbr();
    return abbrs[abbr] || abbr;
};
function Analytics(props) {

    // console.log("URL - " + props.APP_API_URL);
    const { user } = useTypedSelector((state) => state.authState);


    const [env, setEnv] = useState(user?.env ? user.env : "Select Env");
    const [tabValue, setTabValue] = React.useState(1);
    const [calendaranchorEl, setcalendarAnchorEl] = React.useState(null);
    const [agentanchorEl, setagentAnchorEl] = React.useState(null);
    const [value, setValue] = React.useState([null, null]);
    const [projects, setProjects] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [graphDetails, setGraphDetails] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [tileData, setTileData] = useState([]);
    const [customeRange, setCustomeRange] = useState({
        startDt: moment(new Date()).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
        endDt: moment(new Date()),
    });

    const [analyticsParams, setAnalyticsParams] = useState({
        agentId: "",
        startDt: moment(new Date()).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
        endDt: moment(new Date()),
        tabId: tabValue,
        env: env
    });
    const [calendarVal, setCalendarVal] = React.useState("Today");
    const [expanded, setExpanded] = React.useState(false);

    const handleAccChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    React.useEffect(() => {
        if (env && env !== "Select Env")
            getProjects();
    }, [env])

    React.useEffect(() => {
        if (analyticsParams.agentId !== "") {
            setIsLoading(true)
            getAnalytics();
        }
    }, [analyticsParams])

    const getProjects = () => {
        setIsLoading(true)
        const requestOptions = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + user.token
            },
            body: JSON.stringify({
                "env": env
            })
        };
        fetch(props.APP_API_URL + "/agentlist", requestOptions)
            .then(response => response.json())
            .then(data => {
                // console.log("ProjectsUrl result", data);
                const projectData = data._embedded.projects.filter(item => item.name.indexOf("Temp") === -1).map(proj => { return { name: proj.name, id: proj._links.self.href.split('projects/')[1] } });
                setProjects([...projectData]);
                let prodProj = projectData.find(item => item.name.toLowerCase().indexOf("prod") !== -1);
                // console.log("Formated data", projectData)
                const settingOptions = {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": "Bearer " + user.token
                    },
                    body: JSON.stringify({
                        "env": env,
                        id: prodProj.id
                    })
                };
                fetch(props.APP_API_URL + "/agentsettings", settingOptions)
                    .then(response => response.json())
                    .then(settingData => {
                        setAnalyticsParams({
                            ...analyticsParams, ...{
                                startDt: moment.utc(moment.tz(new Date(), settingData.timezone).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })),
                                endDt: moment.utc(moment.tz(new Date(), settingData.timezone)),
                                agentId: prodProj.id,
                                name: prodProj.name,
                                tz: settingData.timezone,
                                env: env
                            }
                        })
                    });

                setIsLoading(false)
            })
            .catch(error => {
                // console.log(error);
                setIsLoading(false)
                // setIsLoading(false);
                // setAlertMessage("Something went wrong. Please try again later.")
                // setShow(true);
            });
    }


    const handleEnvSelection = (event) => {
        // console.log(event.target.value)
        setEnv(event.target.value)
    }

    const handleAgentSelection = (event) => {
        let agent = projects.find(item => item.id === event.target.value)
        setAnalyticsParams({
            ...analyticsParams, ...{
                agentId: event.target.value,
                name: agent.name
            }
        })
    }

    const handleCalendarClick = (event) => {
        setcalendarAnchorEl(event.currentTarget);
    };

    const handleRelativeClick = (count, title) => {
        setCalendarVal(`Last ${count} ${title}`)
        // console.log(`Last ${count} ${title}`)
        let startDt = moment.utc(moment.tz(new Date(), analyticsParams.tz).subtract(count, title).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
        let endDt = moment.utc(moment.tz(new Date(), analyticsParams.tz));
        setAnalyticsParams({
            ...analyticsParams, ...{
                startDt: startDt,
                endDt: endDt
            }
        })
        setcalendarAnchorEl(null);
    };

    const handleTodaylick = () => {
        setCalendarVal(`Today`)
        // // console.log(`Last ${count} ${title}`)
        let startDt = moment.utc(moment.tz(new Date(), analyticsParams.tz).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
        let endDt = moment.utc(moment.tz(new Date(), analyticsParams.tz));
        setAnalyticsParams({
            ...analyticsParams, ...{
                startDt: startDt,
                endDt: endDt
            }
        })
        setcalendarAnchorEl(null);
    };

    const handleApplylick = () => {
        // // console.log(`Last ${count} ${title}`)
        // // console.log(JSON.stringify(customeRange));

        // console.log(value);
        if (!value[0] && !value[1]) {

            return;

        }
        setCalendarVal(`${moment(value[0]).format("MM-DD-YYYY")} to ${moment(value[1]).format("MM-DD-YYYY")}`)
        // let start = moment(value[0]).format("YYYY-MM-DDT00:00:00");
        let startDt = moment(moment.tz(value[0], analyticsParams.tz).add(1, "days").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
        // // console.log(start);
        let endDt = moment(moment.tz(value[1], analyticsParams.tz).add(1, "days").set({ hour: 24, minute: 0, second: 0, millisecond: 0 }));
        // console.log(endDt);

        setAnalyticsParams({
            ...analyticsParams, ...{
                startDt: startDt,
                endDt: endDt
            }
        })
        setcalendarAnchorEl(null);
    };



    const getAnalytics = () => {
        setIsLoading(true)
        const requestOptions = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + user.token
            },
            body: JSON.stringify(analyticsParams)
        };
        fetch(props.APP_API_URL + "/getAnalytics", requestOptions)
            .then(response => response.json())
            .then(data => {
                // console.log("ProjectsUrl result", data);
                // const projectData = data._embedded.projects.map(proj => { return { name: proj.name, id: proj._links.self.href.split('projects/')[1] } });
                setGraphData(data.graphData);
                setTileData(data.tileData);
                setGraphDetails(data.graphDetails);
                setIsLoading(false)
                // // console.log("Formated data", projectData)
            })
            .catch(error => {
                // console.log(error);
                setIsLoading(false)
                // setIsLoading(false);
                // setAlertMessage("Something went wrong. Please try again later.")
                // setShow(true);
            });
    };

    const handleCalendarClose = () => {
        setcalendarAnchorEl(null);
    };

    const calendaropen = Boolean(calendaranchorEl);
    const id = calendaropen ? 'canlendar-simple-popover' : undefined;

    const agentopen = Boolean(agentanchorEl);
    const aid = agentopen ? 'agent-simple-popover' : undefined;
    const handleChange = (event, newValue) => {
        setAnalyticsParams({
            ...analyticsParams, ...{
                tabId: newValue
            }
        })
        setTabValue(newValue);

    };

    const downloadClick = event => {
        // XLSX = require('xlsx');
        try {
            var wb = XLSX.utils.book_new();

            var detailsData = XLSX.utils.json_to_sheet(graphDetails.data);
            XLSX.utils.book_append_sheet(wb, detailsData, "Survey Details");
            var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            saveAs(new Blob([wbout], { type: "application/octet-stream" }), `Survey_Data_${new Date().getTime()}.xlsx`);
        } catch (error) {
        }
    };

    return (

        <Box sx={{ display: 'flex' }}>
            {user && user.roles ? <>
            <CssBaseline />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <NavBar page={"analytics"} />
            
            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 2 }}>

                <Grid container>
                    <Grid item xs={4} md={4}>
                        <Typography variant="h6" component="h6"
                            style={{ fontWeight: 'bold', padding: '10px', color: '#202020' }}>
                            Analytics
                        </Typography>
                    </Grid>
                    <Grid item xs={2} md={2}>
                        {!user.env && <FormControl size="small" style={{ margin: '8px', width: "90%" }}>
                            <InputLabel id="demo-env-select-label">Select Env</InputLabel>
                            <Select
                                style={{ fontSize: "0.85rem", marginTop: "2px" }}
                                labelId="demo-env-select-label"
                                id="demo-env-select"
                                value={env}
                                label="Select Env"
                                onChange={handleEnvSelection}
                            >

                                {user.env && <MenuItem value={user.env}>{user.env}</MenuItem>}
                                {!user.env && <MenuItem value={"Select Env"} disabled>Select Environment</MenuItem>}
                                {!user.env && <MenuItem value={"frontier"}>Frontier</MenuItem>}
                                {!user.env && <MenuItem value={"fpshr"}>FPSHr</MenuItem>}                                
                            </Select>
                        </FormControl>}
                    </Grid>
                    <Grid item xs={3} md={3}>
                        {/* <Button
                            style={{ margin: "10px", width: "90%" }}
                            size="small"
                            aria-describedby={id} variant="outlined" onClick={handleAgentClick}>
                            <Grid container spacing={2} >
                                <Grid item xs={6} style={{ textAlign: "left" }}>
                                    Select Bot
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: "right" }}>
                                    <ArrowDropDownIcon />
                                </Grid>
                            </Grid>
                        </Button>
                        <Popover
                            id={aid}
                            open={agentopen}
                            anchorEl={agentanchorEl}
                            onClose={handleAgentClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}>
                                Hello                                
                        </Popover> */}
                        <FormControl size="small" fullWidth style={{ margin: '8px' }}>
                            <InputLabel id="demo-simple-select-label">Select Bot Agent</InputLabel>
                            <Select
                                style={{ fontSize: "0.85rem", marginTop: "2px" }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={analyticsParams.agentId}
                                label="Select Bot Agent"
                                onChange={handleAgentSelection}
                            >
                                <MenuItem value={"Select Bot Agent"} disabled>Select Bot Agent</MenuItem>
                                {projects && projects.map((item, index) => { return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem> })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} md={3}>
                        <Button
                            style={{ margin: "10px", width: "100%", textTransform: "none" }}
                            aria-describedby={id} variant="outlined" onClick={handleCalendarClick}>
                            <Grid container spacing={2} >
                                <Grid item xs={10} style={{ textAlign: "left" }}>
                                    {calendarVal}
                                </Grid>
                                <Grid item xs={2} style={{ textAlign: "right" }}>
                                    <Today />
                                </Grid>
                            </Grid>
                        </Button>
                        <Popover
                            id={id}
                            open={calendaropen}
                            anchorEl={calendaranchorEl}
                            onClose={handleCalendarClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <Paper variant="outlined" style={{ width: "500px", padding: "10px" }}>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleAccChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography sx={{ width: '33%', flexShrink: 0, marginTop: "5px" }}>Relative</Typography>
                                        <Button
                                            style={{ textTransform: "none" }}
                                            size="small"
                                            onClick={() => { handleTodaylick() }}
                                            variant="outlined">
                                            Today
                                        </Button>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2} >
                                            <Grid item xs={12}>
                                                <Typography>Days</Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button
                                                    style={{ textTransform: "none" }}
                                                    size="small"
                                                    onClick={() => { handleRelativeClick(1, "days") }}
                                                    variant="outlined">
                                                    1
                                                </Button>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button
                                                    style={{ textTransform: "none" }}
                                                    size="small"
                                                    onClick={() => { handleRelativeClick(2, "days") }}
                                                    variant="outlined">
                                                    2
                                                </Button>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button
                                                    style={{ textTransform: "none" }}
                                                    size="small"
                                                    onClick={() => { handleRelativeClick(3, "days") }}
                                                    variant="outlined">
                                                    3
                                                </Button>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button
                                                    style={{ textTransform: "none" }}
                                                    size="small"
                                                    onClick={() => { handleRelativeClick(4, "days") }}
                                                    variant="outlined">
                                                    4
                                                </Button>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button
                                                    style={{ textTransform: "none" }}
                                                    size="small"
                                                    onClick={() => { handleRelativeClick(5, "days") }}
                                                    variant="outlined">
                                                    5
                                                </Button>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button
                                                    style={{ textTransform: "none" }}
                                                    size="small"
                                                    onClick={() => { handleRelativeClick(6, "days") }}
                                                    variant="outlined">
                                                    6
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography>Weeks</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Button
                                                    style={{ textTransform: "none" }}
                                                    size="small"
                                                    onClick={() => { handleRelativeClick(1, "weeks") }}
                                                    aria-describedby={id} variant="outlined">
                                                    1
                                                </Button>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Button
                                                    style={{ textTransform: "none" }}
                                                    size="small"
                                                    onClick={() => { handleRelativeClick(2, "weeks") }}
                                                    aria-describedby={id} variant="outlined">
                                                    2
                                                </Button>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Button
                                                    style={{ textTransform: "none" }}
                                                    size="small"
                                                    onClick={() => { handleRelativeClick(3, "weeks") }}
                                                    aria-describedby={id} variant="outlined">
                                                    3
                                                </Button>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Button
                                                    style={{ textTransform: "none" }}
                                                    size="small"
                                                    onClick={() => { handleRelativeClick(4, "weeks") }}
                                                    aria-describedby={id} variant="outlined">
                                                    4
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel2'} onChange={handleAccChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>Date Range</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DateRangePicker
                                                disableFuture
                                                startText="From date"
                                                endText="End date"
                                                value={value}
                                                onChange={(newValue) => {

                                                    setValue(newValue);
                                                }}
                                                renderInput={(startProps, endProps) => (
                                                    <React.Fragment>
                                                        <TextField {...startProps} />
                                                        <Box sx={{ mx: 2 }}> to </Box>
                                                        <TextField {...endProps} />
                                                        <Box sx={{ mx: 2 }}>
                                                            <Button
                                                                size="small"
                                                                onClick={handleApplylick}
                                                                disabled={value[0] && value[1]? false : true}
                                                                aria-describedby={id} variant="contained">
                                                                Apply
                                                            </Button> </Box>

                                                    </React.Fragment>
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </AccordionDetails>
                                </Accordion>
                            </Paper>
                        </Popover>
                        {analyticsParams.tz && <Typography variant="subtitle1" component="p"
                            style={{ paddingLeft: "20px", fontSize: "x-small", color: "dimgrey" }}>
                            Time zone - {moment.tz(analyticsParams.tz).format('zz')} ({analyticsParams.tz})
                        </Typography>}
                    </Grid>
                </Grid>

                <br />
                <Box>
                    <Grid container>
                        {tileData && tileData.map(item => {
                            return <Grid item xs={4}>
                                <Tile data={item} />
                            </Grid>
                        })}
                    </Grid>
                </Box>
                <br />
                <TabContext value={tabValue} >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Conversations" value={1} />
                            <Tab label="Intents" value={2} />
                            <Tab label="Containment" value={3} />
                            <Tab label="Survey" value={4} />
                            <Tab label="Utterances" disabled value={5} />

                        </TabList>
                    </Box>
                    <TabPanel value={1}>
                        <Box>
                            <Grid container row >
                                <Grid item xs={12} md={12}>
                                    {graphData && graphData.length > 0 && graphData.map(graph => {
                                        return <HighchartsReact
                                            highcharts={Highcharts}
                                            options={graph}
                                        />
                                    })}
                                </Grid>
                            </Grid>
                        </Box>
                    </TabPanel>
                    <TabPanel value={2}>
                        <Box>
                            <Grid container row >
                                <Grid item xs={12} md={12}>
                                    {graphData && graphData.length > 0 && graphData.map(graph => {
                                        return <HighchartsReact
                                            highcharts={Highcharts}
                                            options={graph}
                                        />
                                    })}
                                </Grid>
                            </Grid>
                        </Box>
                    </TabPanel>
                    <TabPanel value={3}>
                        <Box>
                            <Grid container row>
                                <Grid item xs={12} md={12}>
                                    {graphData && graphData.length > 0 && graphData.map(graph => {
                                        return <HighchartsReact
                                            highcharts={Highcharts}
                                            options={graph}
                                        />
                                    })}
                                </Grid>
                            </Grid>
                        </Box>
                    </TabPanel>
                    <TabPanel value={4}>
                        <Box>
                            <Grid container row>
                                <Grid item xs={12} md={12}>
                                    {graphData && graphData.length > 0 && graphData.map(graph => {
                                        return <HighchartsReact
                                            highcharts={Highcharts}
                                            options={graph}
                                        />
                                    })}
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    {graphDetails && graphDetails.title && <AccuracyTables
                                        details={graphDetails}
                                        onSubmit={downloadClick}
                                    />}
                                </Grid>
                            </Grid>
                        </Box>
                    </TabPanel>
                </TabContext>
            </Box>
            </> : <UnAuth/>}
        </Box>

    );
}


export default Analytics;

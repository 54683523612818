import {
  BEGIN_AUTO_LOGIN,
  BEGIN_LOGIN,
  BEGIN_LOGOUT,
  FAILED_AUTO_LOGIN,
  FAILED_LOGIN,
  FAILED_LOGOUT,
  SUCCESS_AUTO_LOGIN,
  SUCCESS_LOGIN,
  SUCCESS_LOGOUT,
} from "./Authorization.action";

const initialState = {
  status: {
    loading: false,
  },
  authenticated: false,
  initialized: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case BEGIN_LOGIN:
    case BEGIN_LOGOUT:
    case BEGIN_AUTO_LOGIN:
      return { ...state, status: { loading: true } };
    case SUCCESS_LOGIN:
    case SUCCESS_AUTO_LOGIN:
      return {
        ...state,
        status: {
          loading: false,
          success: true,
        },
        user: action.payload.user,
        authenticated: true,
        initialized: true,
      };
    case SUCCESS_LOGOUT:
      return {
        ...initialState,
        initialized: true,
        status: { loading: false, success: true },
      };
    case FAILED_LOGOUT:
      return { ...initialState, status: { loading: false, success: false } };
    case FAILED_LOGIN:
    case FAILED_AUTO_LOGIN:
      return {
        ...state,
        initialized: true,
        status: { loading: false, success: false },
      };
    default:
      return { ...state };
  }
};

export default authReducer;

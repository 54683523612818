import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function ConfusionMatrix(props) {
  // console.log("Props", props)
  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <Typography variant="h6" component="h6"
        style={{ fontWeight: 'bold', padding: '10px', color: '#202020', fontSize: '15px' }}>
        {props.details && "Confusion Matrix"}
      </Typography>
      {props.details && props.details.data.length > 6? "Please download Report to view Confusion Matrix" : 
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader sx={{ maxWidth: 800 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Expected&#x2193; Identified&#x2192;</TableCell>              
              {props.details && props.details.data.map((head, index) => (
                <TableCell key={index} style={{ fontWeight: 'bold', textAlign: 'center' }}>{head.intent}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.details && props.details.data.map((row, pindex) => (
              <TableRow
              key={`ctr${pindex}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell key={`${pindex}ctd`} style={{fontWeight: 'bold'}}>{row.intent}</TableCell>
              {row.confusionData && row.confusionData.map((element, index) => (                
                  <TableCell 
                    scope="row"
                    style={row.intent === Object.keys(element)[0] ? { backgroundColor: 'lightgreen', fontWeight: 'bold', textAlign: 'center' } : { textAlign: 'center' }}>
                    {Object.values(element)}
                  </TableCell>
              ))}
              </TableRow>
            ))}

          </TableBody>
        </Table>
      </TableContainer>
}
    </Box>
  );
}

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import parse from 'html-react-parser';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import TextField from '@mui/material/TextField';
import htmlToDraft from 'html-to-draftjs';

export default function BroadcastMessage(props) {
    // const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    // console.log("props.value", props.value)
    // let _contentState = ContentState.createFromText(props?.value?.web ? props?.value?.web : "");
    // const raw = convertToRaw(_contentState)
    // const [contentState, setContentState] = React.useState(raw)
    // React.useEffect(() => {
    //     dataHtml()
    // }, [props?.value?.web])

    // const dataHtml = () => {
    //     const html = props?.value?.web ? props?.value?.web : ""
    //     const contentBlock = htmlToDraft(html);
    //     if (contentBlock) {
    //         const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    //         const editorState = EditorState.createWithContent(contentState);
    //         setEditorState(editorState);
    //     }
    // }

    // const handleEditorChange = (state) => {
    //     setEditorState(state);
    //     convertContentToHTML();
    // }
    // const convertContentToHTML = () => {
    //     let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    //     props.handleMessage(currentContentAsHTML, "web");
    // }

    return (
        <TableContainer>
            <Table aria-label="simple table">
                <TableBody>
                    {Object.entries(props.value).map(([pkey, pvalue]) => (
                        <>
                        <TableRow
                            key={pkey}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            style={{ whiteSpace: "pre-wrap" }}
                        >
                            <TableCell component="th" colSpan={2} scope="row" style={{ textTransform:"capitalize", fontWeight: "bold" }}>
                                **For  {pkey}                  
                            </TableCell>
                            {/* <TableCell component="th" scope="row" style={{ textTransform:"capitalize", fontWeight: "bold" }}>
                                {pkey}                           
                            </TableCell>                             */}
                        </TableRow>
                        {Object.entries(pvalue).map(([key, value]) => (
                            <TableRow
                                key={key}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                style={{ whiteSpace: "pre-wrap" }}
                            >
                                <TableCell component="th" scope="row" style={{width:"20px", fontWeight: "bold" }}>
                                    {key}
                                </TableCell>
                                {key == "web" ? <TableCell>
                                    <div>
                                        {/* {props.isEdit ? <Editor
                                    editorState={editorState}
                                    // defaultContentState={contentState}
                                    onContentStateChange={setContentState}
                                    onEditorStateChange={handleEditorChange}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                // onEditorStateChange={setEditorState}
                                /> : <div */}
                                        {props.isEdit ? <TextField
                                            hiddenLabel
                                            id="outlined-size-small"
                                            variant='filled'
                                            multiline
                                            rows={3}
                                            fullWidth
                                            value={value}
                                            onChange={(event) => props.handleMessage(event, key, pkey)}
                                            size="small"
                                            style={{ marginTop: '5px' }} /> : <div style={{ padding: "5px", backgroundColor: "lightgrey" }}
                                                dangerouslySetInnerHTML={{ __html: value }}
                                        />}
                                    </div>
                                </TableCell> : <TableCell > {props.isEdit ? <TextField
                                    hiddenLabel
                                    id="outlined-size-small"
                                    variant='filled'
                                    multiline
                                    rows={2}
                                    fullWidth
                                    value={value}
                                    onChange={(event) => props.handleMessage(event, key, pkey)}
                                    size="small"
                                    style={{ marginTop: '5px' }} /> : value}
                                </TableCell>}
                            </TableRow>
                        ))}
                        </>
                    ))}

                </TableBody>
            </Table>
        </TableContainer>
    );
}

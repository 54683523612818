import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export default function SimpleText(props) {
    return (
        <Paper style={{ padding: '5px' }}>
            <Typography variant="subtitle1" style={{textTransform: 'capitalize'}}>
                {props.data.type}
            </Typography>
            <Typography variant="body2" noWrap>
                {props.data.message}
            </Typography>
        </Paper>
    );
}

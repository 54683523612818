import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import BotAccuracy from "./pages/BotAccuracy";
import ConvTest from "./pages/ConvTest";
import BotResponses from "./pages/BotResponses";
import Home from "./pages/Home";
import Analytics from "./pages/Analytics";
import BotModel from "./pages/BotModel";
import UserManagement from "./pages/UserManagement";
import ProtectedRoute from "./ProtectedRoute";
import UnAuth from "./pages/UnAuth";

const APP_API_URL = "https://api.botomatic.ai";
// const APP_API_URL = "http://devback-boto.test.fpsinc.com:3030";
// const APP_API_URL = "";
// const APP_API_URL = process.env.REACT_APP_BASE_URL;
// console.log("APP_API_URL", APP_API_URL)


function Router() {
  return (
    <BrowserRouter>
      {/* <NavBar /> */}
      <Routes>
        <Route path="/" element={<Analytics APP_API_URL={APP_API_URL}/>} />   
        <Route path="/unauth" element={<UnAuth />} />             
        <Route exact path="/" element={<ProtectedRoute />}>
          <Route path="/analytics" element={<Analytics APP_API_URL={APP_API_URL}/>} />
          <Route path="/home" element={<Analytics APP_API_URL={APP_API_URL}/>} />
          <Route path="/botaccuracy" element={<BotAccuracy APP_API_URL={APP_API_URL}/>} />
          <Route path="/conversation" element={<ConvTest APP_API_URL={APP_API_URL}/>} />
          <Route path="/botresponses" element={<BotResponses APP_API_URL={APP_API_URL}/>} />
          <Route path="/botconfig" element={<BotModel APP_API_URL={APP_API_URL}/>} />
          <Route path="/users" element={<UserManagement page={"users"} title={"Users"} APP_API_URL={APP_API_URL}/>} />
          <Route path="/groups" element={<UserManagement page={"groups"} title={"Users Groups"} APP_API_URL={APP_API_URL}/>} />
        </Route>        
      </Routes>
    </BrowserRouter>
  );
}

export default Router;

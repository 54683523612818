import { Auth } from "aws-amplify";
const APP_API_URL = "https://api.botomatic.ai";
// const APP_API_URL = "";
// const APP_API_URL = "http://devback-boto.test.fpsinc.com:3030";
// const APP_API_URL = process.env.REACT_APP_BASE_URL;
// console.log("APP_API_URL", APP_API_URL)

export const BEGIN_LOGIN = "@/BEGIN_LOGIN";
export const SUCCESS_LOGIN = "@/SUCCESS_LOGIN";
export const FAILED_LOGIN = "@/FAILED_LOGIN";
export const BEGIN_LOGOUT = "@/BEGIN_LOGOUT";
export const SUCCESS_LOGOUT = "@/SUCCESS_LOGOUT";
export const FAILED_LOGOUT = "@/FAILED_LOGOUT";
export const BEGIN_AUTO_LOGIN = "@@/BEGIN_AUTO_LOGIN";
export const SUCCESS_AUTO_LOGIN = "@@/SUCCESS_AUTO_LOGIN";
export const FAILED_AUTO_LOGIN = "@@/FAILED_AUTO_LOGIN";

const getUserData = async () => {
  const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
  const currentSession = await Auth.currentSession();
  const userGroups = currentSession.getAccessToken().decodePayload()[
    "cognito:groups"
  ];
  const { email, name, phone_number } = currentAuthenticatedUser.attributes;
  const user = {
    email,
    name,
    roles: userGroups,
    phoneNumber: phone_number,
  };

  const options = {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      "email": user.email,
      roles: userGroups
    })
  };
  const userResponse = await fetch(APP_API_URL+"/getUser", options);
  const userRole = await userResponse.json();

  // user.roles = userRole.role;
  if(userGroups){
    if(Array.isArray(userGroups) && userGroups.length>0){
      if(userGroups[0].indexOf("-")){
        const userData = userGroups[0].split("-");
        user.roles = userData[0];
        user.env = userData[1];
      }
    }
  }
  user.token = userRole.token;

  return user;
};

export const autoLoginAction = () => async (dispatch) => {
  dispatch({ type: BEGIN_AUTO_LOGIN });
  try {
    const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
    if (currentAuthenticatedUser) {
      const user = await getUserData();
      dispatch({
        type: SUCCESS_AUTO_LOGIN,
        payload: { user },
      });
    } else {
      throw new Error("User Session not found!");
    }
  } catch (error) {
    dispatch({ type: FAILED_AUTO_LOGIN });
  }
};

export const loginUserAction = () => async (dispatch) => {
  dispatch({ type: BEGIN_LOGIN });
  try {
    const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
    if (currentAuthenticatedUser) {
      const user = await getUserData();
      dispatch({
        type: SUCCESS_LOGIN,
        payload: { user },
      });
    } else {
      throw new Error("User Session not found!");
    }
  } catch (error) {
    dispatch({ type: FAILED_LOGIN });
  }
};

export const logoutAction =
  () =>
    async (dispatch) => {
      dispatch({ type: BEGIN_LOGOUT });
      try {
        await Auth.signOut();
        dispatch({
          type: SUCCESS_LOGOUT,
        });
      } catch (error) {
        dispatch({ type: FAILED_LOGIN });
      }
    };

import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import { useTypedSelector } from "../redux/root.reducer";
import { useDispatch } from "react-redux";
import { logoutAction } from "../redux/Authorization/Authorization.action";
import Admin from "./navigation/admin";
import User from "./navigation/user";
import Developer from "./navigation/developer";
import UnAuth from "./navigation/unauth";

const drawerWidth = 240;

export default function PermanentDrawerLeft(props) {
  const { authenticated } = useTypedSelector((state) => state.authState);
  const { user } = useTypedSelector((state) => state.authState);
  // console.log("user", user)
  const dispatch = useDispatch();
  const logout = () => {
      dispatch(logoutAction());
  }

  return (
    <Box sx={{ display: "flex"}} >
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"                
      >
        <Typography
          variant="h6"
          component="h6"
          style={{ fontWeight: "bold", padding: "10px", color: "navy" }}
        >
          Botomatic
        </Typography>
        {user && user.roles === "admin" && <Admin params= {props} user={user} authenticated={authenticated} logout = {logout}/>}
        {user && user.roles === "user" && <User params= {props} user={user} authenticated={authenticated} logout = {logout}/>}
        {user && user.roles === "developer" && <Developer params= {props} user={user} authenticated={authenticated} logout = {logout}/>}
        {user && !user.roles && <UnAuth params= {props} user={user} authenticated={authenticated} logout = {logout}/>}
      </Drawer>
    </Box>
  );
}

import { useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import authReducer from './Authorization/Authorization.reducer';

export const useTypedSelector = useSelector;

export const rootReducer = combineReducers({
  authState: authReducer,
});

export default rootReducer;
